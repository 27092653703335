var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoginApi } from "../login.api";
import { modalServiceSubject } from "../../components/modal/modal";
import Modal from 'react-modal';
import { customStyles } from "../../definitions/constants/modal.constant";
export default function ForgetPassword(props) {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var onSubmit = function (data) {
        callChangePasswordApi(data);
    };
    var callChangePasswordApi = function (data) {
        LoginApi.forgetPassword(data).then(function (result) {
            if (result.status) {
                modalServiceSubject.next({
                    title: "Başarılı!",
                    content: "Parolanızı sıfırlama linki kayıtlı e-posta adresinize ve telefon numaranıza gönderilmiştir.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: "Kullanıcı kaydı bulunamadı.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    function closeModal() {
        setIsModalOpen(false);
    }
    function openModal() {
        setIsModalOpen(true);
    }
    return (_jsxs("div", __assign({ style: { marginTop: props.page == "footer" ? '0' : '-55px' }, id: 'forgotPassword' }, { children: [props.page == "footer" ?
                (_jsx("li", __assign({ className: "link", onClick: openModal, style: { cursor: "pointer" } }, { children: _jsx("a", __assign({ href: "#" }, { children: "\u015Eifremi Unuttum" }), void 0) }), void 0)) : (_jsx("div", __assign({ className: "text-secondary cursor-pointer py-2" }, { children: _jsx("span", __assign({ className: "float-right", onClick: openModal }, { children: "\u015Eifremi unuttum ? " }), void 0) }), void 0)), _jsxs(Modal, __assign({ isOpen: isModalOpen, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h3", { children: "Parolam\u0131 Hat\u0131rlat" }, void 0) }), void 0), _jsx("div", __assign({ className: "modal-body", style: { width: '280px' } }, { children: _jsx("div", { children: _jsxs("div", { children: [_jsx("label", { children: "\u00DCye No / TC No / E-posta Giriniz " }, void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", __assign({ className: "form-control" }, register('username', {
                                                required: true,
                                                minLength: 5,
                                            })), void 0), _jsxs("div", { children: [errors.username && errors.username.type === "required" &&
                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "\u00DCye NO / TC No / E-posta gereklidir." }), void 0), errors.username && errors.username.type === "minLength" &&
                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "\u00DCye NO / TC No / E-posta hatal\u0131" }), void 0)] }, void 0), _jsx("input", { className: "btn btn-primary float-right mt-3", type: "submit" }, void 0)] }), void 0)] }, void 0) }, void 0) }), void 0)] }), void 0)] }), void 0));
}
