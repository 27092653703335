var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { LiveScoreStatusType } from "../../definitions/constants/event-status-type.constant";
import "../../assets/css/betradar.css";
import { ServerDate, useForceUpdate } from "../../lib/misc.functions";
import { marketCategories, marketNamesMappingData, newMarkets, rapidMarkets } from "../../definitions/constants/market-categories.constant";
import { eventDetailSubject, eventUpdateSubject, marketMapping } from "../../store/sportsbook.store";
import { Odd } from "./components/odd";
import _ from "lodash";
import { commentUpdateSubject, getPostComment, sharePost } from "../../services/sosyoley.services";
import { EventComments } from "./widgets/comments";
import { getLiveStreamUrl } from "../../services/sportsbook.services";
import { member } from "../../store/login.store";
import { modalServiceSubject } from "../../components/modal/modal";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { inProgress } from "../../app";
import { CommentWidget } from "../sosyoley/components/comments";
import { betSlipActionSubject } from "../../store/betslip.store";
import { EventDetailHeader } from "./widgets/eventdetail.header";
import { isAndroid, isIOS, isMacOs, isWindows, osVersion } from "react-device-detect";
import rapidIcon from "../../assets/img/rapid.svg";
export var eventDetailVisiblity = { visible: false };
// get Bombay time
var event = false;
var liveStreamPlayer;
var updateTimer;
export function EventDetail(props) {
    var _this = this;
    var marketGroups = [];
    var eventMarkets = [];
    var eventMarketMapping = {};
    var statisticUrl;
    var closeEventDetail = props.closeEventDetail;
    var eventType = props.eventType;
    var sportSlug = props.sportSlug;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useState(false), showMarketGroup = _a[0], setShowMarketGroup = _a[1];
    var _b = useState(1), showTabs = _b[0], setShowTabs = _b[1];
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (typeof events === 'object' && event) {
                if (['remove', 'toggle'].includes(events.action)) {
                    if (event && events.betSlip.sportEvent && events.betSlip.sportEvent.eventId === event.eventId) {
                        forceUpdate();
                    }
                }
            }
        });
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (event && events.includes(event.eventId)) {
                clearTimeout(updateTimer);
                updateTimer = setTimeout(function () {
                    forceUpdate();
                }, 300);
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
            eventUpdateSubject_subscription.unsubscribe();
            clearTimeout(updateTimer);
        };
    }, []);
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            event = res;
            if (res && res !== 'toggle') {
                console.log("Event Data >> ", res);
                console.log("Market Data >> ", marketMapping[res.eventId]);
                try {
                    //@ts-ignore
                    document.getElementById("event-content").scrollTo(0, 0);
                }
                catch (e) { }
                setShowTabs(1);
                setShowMarketGroup('Tümü');
                eventDetailVisiblity.visible = true;
                forceUpdate();
            }
            else {
                event = null;
                eventDetailVisiblity.visible = false;
                forceUpdate();
            }
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
            event = null;
        };
    }, []);
    if (event) {
        // fixme burada neden sadece futbol var
        var _islive = event.isLiveEvent ? event.sportSlug !== 'futbol' : event.bettingPhase;
        // console.log(event,'event')
        // statisticUrl = env.website_url + '/assets/new-widgets/' + event.sportSlug + '.html?matchId=' + event.broadageId + '&team1=' + event.eventParticipant[0].broadageId + '&isLive=' + _islive + '&team2=' + event.eventParticipant[1].broadageId;
        statisticUrl = '/assets/new-widgets/widget.html' + '?sportId=' + event.sportId + '&matchId=' + event.theSportsId;
        if (event.liveScoreStatus && event.matchPlayTime && event.currentScore) {
            var time_statuses = ["520", "521", "522", "523", "700"];
            if (time_statuses.includes(event.liveScoreStatus.toString())) {
                event.minute = event.matchPlayTime + '.dk';
            }
            else {
                event.minute = LiveScoreStatusType[Number(event.liveScoreStatus)];
            }
        }
        // eventMarkets = marketMapping[event.eventId].filter((el: EventMarket) =>{
        //   console.log(new Date(el.sellEndDate) , ServerDate() , new Date(el.sellBeginDate) ,  ServerDate())
        //   return el
        //   }
        // )
        eventMarkets = marketMapping[event.eventId].filter(function (el) {
            return (Number(el.channelWeb) === 1 && el.marketStatus < 2 && el.marketStatus !== 0 && new Date(el.sellEndDate) > ServerDate() && new Date(el.sellBeginDate) < ServerDate());
        });
        marketGroups = marketCategories[event.sportSlug];
        for (var _i = 0, eventMarkets_1 = eventMarkets; _i < eventMarkets_1.length; _i++) {
            var market = eventMarkets_1[_i];
            if (!eventMarketMapping[market.marketType + '-' + market.marketSubType]) {
                eventMarketMapping[market.marketType + '-' + market.marketSubType] = [];
            }
            eventMarketMapping[market.marketType + '-' + market.marketSubType].push(market);
        }
    }
    var isFirstMarketForOddsValue;
    var setFirst = function (market) {
        if (marketNamesMappingData[market.marketType + '-' + market.marketSubType] === undefined) {
            isFirstMarketForOddsValue = market.name;
        }
        else {
            isFirstMarketForOddsValue = market.marketType + '-' + market.marketSubType + (['2-7', '2-622'].includes(market.marketType + '-' + market.marketSubType) ? market.specialOddsValue : '');
        }
    };
    var getOdds = function (marketsList) {
        var availableMarkets = eventMarkets.filter(function (market) { return (marketsList.length === 0 || (marketsList.length > 0 && marketsList.includes(market.marketType + '-' + market.marketSubType))) && market.marketStatus < 2; });
        availableMarkets.map(function (market) {
            market.specialOddsValue = Number(market.specialOddsValue);
        });
        var sortedMarketList = _.orderBy(availableMarkets, ['marketTypePriority', 'specialOddsValue']);
        return sortedMarketList.map(function (market, marketKey) {
            return (_jsxs("div", __assign({ className: "market group" }, { children: [isFirstMarketForOddsValue !== market.marketType + '-' + market.marketSubType ?
                        _jsxs("div", __assign({ className: "marketLabel" }, { children: [_jsx("div", __assign({ className: "mbs mbs-".concat(market.minCombiCount) }, { children: market.minCombiCount }), void 0), marketNamesMappingData[market.marketType + '-' + market.marketSubType] ? marketNamesMappingData[market.marketType + '-' + market.marketSubType].name : market.marketName, newMarkets.includes(market.marketType + '-' + market.marketSubType) ? _jsx(_Fragment, { children: _jsx("span", __assign({ className: "newMarket" }, { children: "Yeni" }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), rapidMarkets.includes(market.marketType + '-' + market.marketSubType) ? _jsx(_Fragment, { children: _jsxs("span", __assign({ className: "hizliMarket" }, { children: [_jsx("img", { src: rapidIcon, className: "rapidIcon" }, void 0), " H\u0131zl\u0131"] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), setFirst(market), ['2-7', '2-622'].includes(market.marketType + '-' + market.marketSubType) ? _jsxs("span", { children: [" - ", market.specialOddsValue] }, void 0) : null] }), void 0) :
                        _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ style: { display: "flex", marginLeft: 3, marginRight: 3, flexWrap: "wrap" } }, { children: _jsx(Odd, { event: event, showOutcomeName: true, market: market }, void 0) }), void 0)] }), marketKey));
        });
    };
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit, reset = _c.reset;
    var onSubmit = function (data) {
        if (!inProgress.state) {
            inProgress.state = true;
            data['obj_id'] = event.eventId;
            sharePost(data).then(function (response) {
                inProgress.state = false;
                if (response.status) {
                    response.data.post.member = {
                        member_id: member.id,
                        photo: member.photo,
                        nick_name: member.nick_name,
                    };
                    event.comments.unshift(response.data.post);
                    commentUpdateSubject.next(Number(event.eventId));
                    reset({ comment: "" });
                }
                else {
                    modalServiceSubject.next({
                        title: 'Bilgilendirme!',
                        content: response.message,
                        confirm: {
                            sure: {
                                label: 'Giriş Yap',
                                class: 'btn-warning',
                                action: function () {
                                    navigate('/giris-yap');
                                },
                            },
                            cancel: {
                                label: 'Tamam'
                            }
                        }
                    });
                }
            }).catch(function (e) {
                console.log(e);
                inProgress.state = false;
            });
        }
    };
    var _d = useState(false), postCommentModal = _d[0], setPostCommentModal = _d[1];
    var openPostCommentModal = function (post) {
        if (!inProgress.state) {
            inProgress.state = true;
            getPostComment(post.post_id).then(function (comments) {
                inProgress.state = false;
                setPostCommentModal({ post: post, postComments: comments, });
            });
            inProgress.state = false;
        }
    };
    var getStreamPlayer = function () { return __awaiter(_this, void 0, void 0, function () {
        var liveStreamContainer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    liveStreamContainer = document.getElementById('live-stream-container');
                    return [4 /*yield*/, getLiveStreamUrl(event).then(function (res) {
                            if (event.streamUrl && event.streamUrl.url || (res && res.hasOwnProperty('url'))) {
                                // @ts-ignore
                                liveStreamPlayer = new avvpl.setupPlayer({
                                    id: "live-match-video-container",
                                    autoplay: false,
                                    allowFullScreen: false,
                                    currentQuality: 50,
                                });
                                liveStreamPlayer.remove();
                                var config = {
                                    id: "live-match-video-container",
                                    autoplay: false,
                                    allowFullScreen: innerWidth < 968 ? true : false,
                                    currentQuality: 50,
                                    streamUrl: event.streamUrl.url
                                };
                                // @ts-ignore
                                liveStreamPlayer = new avvpl.setupPlayer(config);
                                if (liveStreamContainer) {
                                    liveStreamContainer.classList.remove('hide');
                                    liveStreamContainer.classList.add('show');
                                }
                                return liveStreamPlayer;
                            }
                            else if (res && res.error) {
                                try {
                                    modalServiceSubject.next({
                                        title: 'Hata!',
                                        content: res.error.message,
                                        confirm: {
                                            cancel: {
                                                label: 'Tamam',
                                            }
                                        }
                                    });
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var closeDetail = function () {
        if ((isAndroid && Number(osVersion.split('.')[0]) >= 9) || isMacOs || isWindows ||
            (isIOS && Number(osVersion.split('.')[0]) >= 15)) {
            if (event) {
                if (sportSlug === 'duello') {
                    navigate('/iddaa/duello/' + eventType + '/');
                }
                else {
                    navigate('/iddaa/' + event.sportSlug + '/' + eventType + '/');
                }
            }
        }
        event = null;
        eventDetailVisiblity.visible = false;
        forceUpdate();
        closeEventDetail();
    };
    if (!event) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs("div", __assign({ id: "eventDetailWrapper", className: "event-detail-wrapper" }, { children: [_jsx(EventDetailHeader, { event: event, navigate: navigate, getStreamPlayer: getStreamPlayer, closeDetail: closeDetail }, void 0), _jsxs("div", __assign({ id: "event-content", className: showTabs === 2 ? 'event-content statistics' : 'event-content ' }, { children: [_jsxs("div", __assign({ id: "live-stream-container", className: "live-stream hide" }, { children: [((new Date(event.eventDate).getTime() - ServerDate().getTime()) / 1000) > 0 && event.liveStreamAvailable === 1 ?
                                _jsx(CountDown, { getStreamPlayer: getStreamPlayer, eventDate: event.eventDate }, void 0)
                                : _jsx(_Fragment, {}, void 0), event.liveStreamAvailable && event.liveStreamAvailable > 0 ? _jsx(_Fragment, { children: _jsx("div", __assign({ id: "live-match-video-container", className: "" }, { children: _jsxs("video", __assign({ style: { width: '100%', height: '100%' } }, { children: [_jsx("source", { type: "video/webm" }, void 0), "Taray\u0131c\u0131n\u0131z bu yay\u0131n sistemini desteklemiyor"] }), void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), eventType !== 2 ? _jsx("div", { id: "lmt-container", className: "sr-widget sr-widget-alt " }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("ul", __assign({ className: "tabs", style: { marginTop: event.sportSlug === 'tenis' ? '4px' : '0' } }, { children: [_jsx("li", __assign({ className: showTabs === 1 ? 'active' : '', onClick: function () { setShowTabs(1); } }, { children: "Oranlar" }), void 0), eventType !== 'uzunvadeli' && ['soccer', 'futbol', 'basketbol', 'tenis', 'buz-hokeyi', 'voleybol'].includes(event.sportSlug) ? _jsx("li", __assign({ className: showTabs === 2 ? 'active' : '', onClick: function () { setShowTabs(2); } }, { children: "\u0130statistik" }), void 0) : null, _jsx("li", __assign({ className: showTabs === 3 ? 'active' : '', onClick: function () { setShowTabs(3); } }, { children: "Yorumlar" }), void 0)] }), void 0), showTabs === 1 && event.eventType !== '2' ? _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "marketGroupTabs" }, { children: marketGroups.map(function (group, groupKey) {
                                    var marketCount = group.markets.filter(function (el) { return eventMarketMapping[el]; });
                                    if (group.name === 'Tümü') {
                                        return _jsxs("a", __assign({ className: showMarketGroup === group.name ? 'active' + " cursor-p" : '' + " cursor-p", onClick: function () { setShowMarketGroup('Tümü'); } }, { children: [group.name, " (", Object.entries(eventMarketMapping).length, ")"] }), groupKey);
                                    }
                                    else {
                                        return marketCount.length > 0 ? (_jsxs("a", __assign({ className: (showMarketGroup === group.name ? 'active' + " cursor-p" : '') + " cursor-p" + (group.name === "Yeni" ? ' newMarket' : '') + (group.name === "Hızlı" ? ' hizliMarket' : ''), onClick: function () { setShowMarketGroup(group.name); } }, { children: [group.name, " (", marketCount.length, ")"] }), groupKey)) : _jsx("div", { style: { display: "none" } }, groupKey);
                                    }
                                }) }), void 0), eventMarkets.length === 0 ? _jsx("div", __assign({ className: "text-center m-5 fw-bold" }, { children: "T\u00FCm marketler kapat\u0131ld\u0131. L\u00FCtfen Bekleyin..." }), void 0) : _jsx(_Fragment, {}, void 0), marketGroups.map(function (group, groupKey) {
                                if (group.name === showMarketGroup) {
                                    return _jsx("div", __assign({ className: "marketGroup " }, { children: _jsx("div", { children: getOdds(group.markets) }, void 0) }), groupKey);
                                }
                                else {
                                    return _jsx("div", { style: { display: "none" } }, groupKey);
                                }
                            })] }, void 0) : _jsx(_Fragment, {}, void 0), showTabs === 1 && event.eventType === '2' ? _jsxs(_Fragment, { children: [eventMarkets.length === 0 ? _jsx("div", __assign({ className: "text-center m-5 fw-bold" }, { children: "T\u00FCm marketler kapat\u0131ld\u0131. L\u00FCtfen Bekleyin..." }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { children: getOdds('3-1') }, void 0), _jsx("p", __assign({ className: "py-5 text-center" }, { children: "G\u00F6sterilen skor veya di\u011Fer istatistikler yaln\u0131zca bilgi ama\u00E7l\u0131d\u0131r. Oley.com, bu bilgilerin do\u011Frulu\u011Fu hakk\u0131nda herhangi bir sorumluluk kabul etmez." }), void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), showTabs === 2 ? _jsx(_Fragment, { children: _jsx("div", __assign({ style: { height: '100%', width: "100%", overflowX: "hidden" } }, { children: _jsx("iframe", { className: "invert-color", style: { height: "100%", border: 0, }, src: statisticUrl, width: "100%" }, void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), showTabs === 3 ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "eventComments" }, { children: [_jsxs("form", __assign({ className: "w-100", onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "writeNow" }, { children: [_jsx("input", __assign({ type: "hidden" }, register('content_type'), { value: 1 }), void 0), _jsx("textarea", __assign({ id: "id_txtComment", placeholder: "T\u00FCyo ver" }, register('comment', {
                                                    required: true,
                                                    minLength: 4,
                                                    maxLength: 1500
                                                })), void 0), _jsx("button", __assign({ className: "btn btn-warning" }, { children: "Payla\u015F" }), void 0)] }), void 0), _jsx("div", __assign({ className: "info mt-1 ml-1" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ defaultChecked: true, type: "checkbox" }, register('comment_perm')), void 0), " Yoruma a\u00E7 ", _jsx("i", __assign({ className: "fs-small" }, { children: "(Di\u011Fer \u00FCyeler payla\u015F\u0131m\u0131ma yorum yapabilir.)" }), void 0)] }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "entries" }, { children: _jsx(EventComments, { event: event, modalState: openPostCommentModal }, void 0) }), void 0), _jsx(CommentWidget, { forceUpdate: forceUpdate, modalState: { postCommentModal: postCommentModal, setPostCommentModal: setPostCommentModal } }, void 0), _jsx("p", __assign({ className: "py-5 text-center" }, { children: "G\u00F6sterilen skor veya di\u011Fer istatistikler yaln\u0131zca bilgi ama\u00E7l\u0131d\u0131r. Oley.com, bu bilgilerin do\u011Frulu\u011Fu hakk\u0131nda herhangi bir sorumluluk kabul etmez." }), void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0));
}
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
var countDownTimer;
export function CountDown(props) {
    var _a = useState(), liveStreamCountDown = _a[0], setLiveStreamCountDown = _a[1];
    var today = ServerDate();
    var eventDate = new Date(props.eventDate);
    var getStreamPlayer = props.getStreamPlayer;
    var secoundsLeft = (eventDate.getTime() - today.getTime()) / 1000;
    useEffect(function () {
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
        return function () {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
            clearTimeout(countDownTimer);
        };
    }, [props.eventDate]);
    clearTimeout(countDownTimer);
    countDownTimer = setTimeout(function () {
        if (secoundsLeft <= 0) {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
            getStreamPlayer();
        }
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
    }, 1000);
    if (!liveStreamCountDown) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: "count-down" }, { children: ["Canl\u0131 Ma\u00E7 Yay\u0131n\u0131 Heyecan\u0131na Kalan S\u00FCre", _jsxs("span", { children: [liveStreamCountDown.h, ":", liveStreamCountDown.m, ":", liveStreamCountDown.s] }, void 0)] }), void 0));
}
