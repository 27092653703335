var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AndroidIosApp from '../../../assets/img/android-ios-app.webp';
import AndroidInstallation from '../../../assets/img/android_installation.webp';
var Android = function () {
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-9" }, { children: [_jsx("h5", { children: " Android Uygulamas\u0131 Nas\u0131l \u0130ndirilir?" }, void 0), _jsx("p", { children: "A\u015Fa\u011F\u0131daki yollardan herhangi birini se\u00E7erek Oley.com Android uygulamas\u0131n\u0131 cep telefonunuza indirebilirsiniz." }, void 0), _jsx("hr", {}, void 0), _jsxs("p", { children: ["1. A\u015Fa\u011F\u0131daki butona t\u0131klayarak uygulamay\u0131 bilgisayar\u0131n\u0131za veya cep telefonunuza indirebilirsiniz.", _jsx("br", {}, void 0), _jsx("strong", { children: "Not:" }, void 0), " Uygulamay\u0131 bilgisayar\u0131n\u0131za indirdikten sonra cep telefonunuza ,aktarman\u0131z gerekir."] }, void 0), _jsx("a", __assign({ href: "https://cdn2.oley.com/app/download.html", target: "_blank" }, { children: "Uygulamay\u0131 \u0130ndir" }), void 0)] }), void 0), _jsx("div", __assign({ className: "col-3" }, { children: _jsx("img", { className: "w-100", src: AndroidIosApp, alt: "Oley Android Uygulama" }, void 0) }), void 0), _jsxs("div", { children: [_jsx("div", {}, void 0), _jsx("hr", {}, void 0), _jsx("h5", { children: " Kurulum Nas\u0131l Yap\u0131l\u0131r?" }, void 0), _jsx("hr", {}, void 0), _jsx("img", { src: AndroidInstallation, alt: "Oley Android Uygulama Kurulumu" }, void 0)] }, void 0)] }), void 0) }, void 0));
};
export default Android;
