var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Api, LoginApi } from '../shared/login.api';
import { Storage } from "../lib/localstorege.service";
import { AuthWSProvider, AuthWS } from "./websocket.services";
import { Member } from "../models/user.model";
import { member } from "../store/login.store";
import { env } from "../definitions/global.vars";
import { modalServiceSubject } from "../components/modal/modal";
import Alert from "../assets/feather/alert-triangle.svg";
import { ApiService } from "../lib/http.service";
import { staticPagesList } from "../store/static.pages.store";
var BASE_PATH = env.core_api;
export var headers = function (accept) { return ({
    Accept: accept,
    "Content-Type": "application/x-www-form-urlencoded"
}); };
Api.setbaseUrl(BASE_PATH);
Api.setHeaders(headers('application/json'));
export var loginApi = LoginApi;
export function doLogin(token) {
    Storage.set('token', token);
    AuthWS.connect(AuthWS.AUTH, token);
    var api = new ApiService();
    api.start('post', env.core_api + '/marketing/api/available-bonus/', Storage.get('token') ? { token: Storage.get('token') } : null, true, 3000)
        .then(function (res) {
        Storage.set('cached_available_bonus', res.data.available_bonus, 60 * 1000);
    });
}
export function doLogout() {
    Storage.del('token');
    Storage.del('cached_available_bonus');
    Object.assign(member, new Member());
}
export function refreshMemberInfo() {
    if (AuthWSProvider && AuthWSProvider.readyState === 1) {
        AuthWSProvider.send(JSON.stringify({ type: {
                $type: "getAccountInfo",
                data: ''
            } }));
    }
}
export function restoreLogin() {
    var exToken = Storage.get('token');
    //console.log("restore login--->>>", exToken)
    if (exToken !== null) {
        doLogin(exToken);
    }
}
export var callLoginApi = function (username, password, option) {
    if (option === void 0) { option = null; }
    loginApi.login({ username: username, password: password, option: option }).then(function (result) {
        if (result.status) {
            try {
                // @ts-ignore
                window.gtag('event', 'conversion', { 'send_to': 'AW-646617036/jCZJCLbn3-0YEMyvqrQC' });
            }
            catch (e) { }
            var token = result.data.token;
            loginApi.checkAouth({ token: token, renew_member_info: true });
            doLogin(token);
        }
        else {
            if (result.data && result.data.hasOwnProperty('check_agrement') && Number(result.data.check_agrement) === 0) {
                var staticPagesData = staticPagesList.find(function (x) { return x.type === 0; });
                modalServiceSubject.next({
                    title: staticPagesData.name,
                    width: '700px',
                    height: 'calc(70vh - 96px)',
                    content: '<div class="p-1">' + staticPagesData.body + '</div>',
                    confirm: {
                        sure: {
                            label: 'Onaylıyorum',
                            action: function () {
                                callLoginApi(username, password, { is_checked: true });
                            }
                        },
                        cancel: {
                            label: 'Reddediyorum',
                            action: function () {
                            }
                        }
                    },
                    timeout: 0
                });
            }
            else if (result.data && result.data.hasOwnProperty('user_passive') && Number(result.data.user_passive) === 1) {
                modalServiceSubject.next({
                    title: "Hesap Aktivasyonu",
                    // width: '700px',
                    height: 'auto',
                    content: '<div class="p-1">Üyeliğiniz talebiniz üzerine kapatılmıştır. Tekrar açmak ister misiniz?</div>',
                    confirm: {
                        sure: {
                            label: 'Hesabımı Tekrar Aktif Et',
                            action: function () {
                                callLoginApi(username, password, { re_activate: true });
                            }
                        },
                        cancel: {
                            label: 'Vazgeç',
                            action: function () {
                            }
                        }
                    },
                    timeout: 0
                });
            }
            else if (result.data && result.data.validation_required && Number(result.data.validation_required) === 0) {
            }
            else {
                modalServiceSubject.next({
                    show: true,
                    title: "Hatalı giriş!",
                    content: '<div class="p-3"><p class="text-center"><img src="' + Alert + '" /><br />' + result.message + '</p></div>',
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        }
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    }).catch(function (e) {
        console.log(e);
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    }).finally(function () {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    });
};
export function renewPasswordApi(data) {
    return __awaiter(this, void 0, void 0, function () {
        var apiServices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServices = new ApiService();
                    return [4 /*yield*/, apiServices
                            .start('post', env.core_api + "/member/api/password-reset/", data, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getNotificationCount(token) {
    return __awaiter(this, void 0, void 0, function () {
        var apiServices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServices = new ApiService();
                    return [4 /*yield*/, apiServices
                            .start('post', "https://notification.oley.com/get_notification_summery/", { token: token }, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
