var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import refreshIcon from '../../../assets/feather/refresh-cw.svg';
import './../katar.css';
import { initialLeftMenu, sportsMenu, useForceUpdate } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
import { member } from "../../../store/login.store";
import { Storage } from "../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../components/modal/modal";
import { useNavigate } from "react-router-dom";
import { Api } from "../../contactus/contactus.api";
import { env } from "../../../definitions/global.vars";
var PredictionGame = function () {
    var navigate = useNavigate();
    // gruplardaki takımlar
    var groupTeams = {
        data: [
            {
                id: 'group_a',
                title: 'A Grubu',
                teams: [
                    {
                        id: 1,
                        short: 'KAT',
                        countryName: 'Qatar',
                        trCountryName: 'Katar'
                    },
                    {
                        id: 2,
                        short: 'EKV',
                        countryName: 'Ecuador',
                        trCountryName: 'Ekvador'
                    },
                    {
                        id: 3,
                        short: 'SEN',
                        countryName: 'Senegal',
                        trCountryName: 'Senegal'
                    },
                    {
                        id: 4,
                        short: 'HOL',
                        countryName: 'Netherlands',
                        trCountryName: 'Hollanda'
                    }
                ]
            },
            {
                id: 'group_b',
                title: 'B Grubu',
                teams: [
                    {
                        id: 5,
                        short: 'İNG',
                        countryName: 'England',
                        trCountryName: 'İngiltere'
                    },
                    {
                        id: 6,
                        short: 'İRN',
                        countryName: 'Iran',
                        trCountryName: 'İran'
                    },
                    {
                        id: 7,
                        short: 'ABD',
                        countryName: 'USA',
                        trCountryName: 'ABD'
                    },
                    {
                        id: 8,
                        short: 'GAL',
                        countryName: 'Wales',
                        trCountryName: 'Galler'
                    }
                ]
            },
            {
                id: 'group_c',
                title: 'C Grubu',
                teams: [{
                        id: 9,
                        short: 'ARJ',
                        countryName: 'Argentina',
                        trCountryName: 'Arjantin'
                    },
                    {
                        id: 10,
                        short: 'SAR',
                        countryName: 'Saudi Arabia',
                        trCountryName: 'Suudi Arabistan'
                    },
                    {
                        id: 11,
                        short: 'MEK',
                        countryName: 'Mexico',
                        trCountryName: 'Meksika'
                    },
                    {
                        id: 12,
                        short: 'POL',
                        countryName: 'Poland',
                        trCountryName: 'Polonya'
                    }]
            },
            {
                id: 'group_d',
                title: 'D Grubu',
                teams: [
                    {
                        id: 13,
                        short: 'FRA',
                        countryName: 'France',
                        trCountryName: 'Fransa'
                    },
                    {
                        id: 14,
                        short: 'AUS',
                        countryName: 'Australia',
                        trCountryName: 'Avustralya'
                    },
                    {
                        id: 15,
                        short: 'DAN',
                        countryName: 'Denmark',
                        trCountryName: 'Danimarka'
                    },
                    {
                        id: 16,
                        short: 'TUN',
                        countryName: 'Tunisia',
                        trCountryName: 'Tunus'
                    }
                ]
            },
            {
                id: 'group_e',
                title: 'E Grubu',
                teams: [
                    {
                        id: 17,
                        short: 'İSP',
                        countryName: 'Spain',
                        trCountryName: 'İspanya'
                    },
                    {
                        id: 18,
                        short: 'KRİ',
                        countryName: 'Costa Rica',
                        trCountryName: 'Kosta Rika'
                    },
                    {
                        id: 19,
                        short: 'ALM',
                        countryName: 'Germany',
                        trCountryName: 'Almanya'
                    },
                    {
                        id: 20,
                        short: 'JAP',
                        countryName: 'Japan',
                        trCountryName: 'Japonya'
                    }
                ]
            },
            {
                id: 'group_f',
                title: 'F Grubu',
                teams: [
                    {
                        id: 21,
                        short: 'BEL',
                        countryName: 'Belgium',
                        trCountryName: 'Belçika'
                    },
                    {
                        id: 22,
                        short: 'KAN',
                        countryName: 'Canada',
                        trCountryName: 'Kanada'
                    },
                    {
                        id: 23,
                        short: 'FAS',
                        countryName: 'Morocco',
                        trCountryName: 'Fas'
                    },
                    {
                        id: 24,
                        short: 'HRV',
                        countryName: 'Croatia',
                        trCountryName: 'Hırvatistan'
                    }
                ]
            },
            {
                id: 'group_g',
                title: 'G Grubu',
                teams: [
                    {
                        id: 25,
                        short: 'BRZ',
                        countryName: 'Brazil',
                        trCountryName: 'Brezilya'
                    },
                    {
                        id: 26,
                        short: 'SRB',
                        countryName: 'Serbia',
                        trCountryName: 'Sırbistan'
                    },
                    {
                        id: 27,
                        short: 'İSV',
                        countryName: 'Switzerland',
                        trCountryName: 'İsviçre'
                    },
                    {
                        id: 28,
                        short: 'KMR',
                        countryName: 'Cameroon',
                        trCountryName: 'Kamerun'
                    }
                ]
            },
            {
                id: 'group_h',
                title: 'H Grubu',
                teams: [
                    {
                        id: 29,
                        short: 'POR',
                        countryName: 'Portugal',
                        trCountryName: 'Portekiz'
                    },
                    {
                        id: 30,
                        short: 'GNA',
                        countryName: 'Ghana',
                        trCountryName: 'Gana'
                    },
                    {
                        id: 31,
                        short: 'URU',
                        countryName: 'Uruguay',
                        trCountryName: 'Uruguay'
                    },
                    {
                        id: 32,
                        short: 'KOR',
                        countryName: 'South Korea',
                        trCountryName: 'Güney Kore'
                    }
                ]
            },
        ]
    };
    var forceUpdate = useForceUpdate();
    var _a = useState({
        group_a: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_b: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_c: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_d: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_e: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_f: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_g: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        group_h: [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, { id: null, name: null }],
        match49: { id: null, name: null },
        match50: { id: null, name: null },
        match51: { id: null, name: null },
        match52: { id: null, name: null },
        match53: { id: null, name: null },
        match54: { id: null, name: null },
        match55: { id: null, name: null },
        match56: { id: null, name: null },
        match57: { id: null, name: null },
        match58: { id: null, name: null },
        match59: { id: null, name: null },
        match60: { id: null, name: null },
        match61: { id: null, name: null },
        match62: { id: null, name: null },
        match64: { id: null, name: null }
    }), teamChoice = _a[0], setTeamChoice = _a[1];
    //bu teamchoice'a eşitlenebilir, kullanıcının default değeri var mı
    var _b = useState(), defaultAnswer = _b[0], setDefaultAnswer = _b[1];
    //gruplara göre değişen maçların indexleri
    var matchIndexesAffectedByGroups = {
        group_a: [49, 51, 57, 59, 61, 62],
        group_b: [49, 51, 57, 59, 61, 62],
        group_c: [50, 52, 57, 59, 61, 62],
        group_d: [50, 52, 57, 59, 61, 62],
        group_e: [53, 55, 58, 60, 61, 62],
        group_f: [53, 55, 58, 60, 61, 62],
        group_g: [54, 56, 58, 60, 61, 62],
        group_h: [54, 56, 58, 60, 61, 62]
    };
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    //kullanıcı varsa gönderdiği veri var mı
    useEffect(function () {
        betSlipVisibleSubject.next(false);
        if (member.id) {
            Api.start('post', env.misc_api + '/product/api/world_cup/', {
                type: 'get',
                token: Storage.get('token')
            }, true).then(function (result) {
                //console.log(result, 'result')
                if (result.status) {
                    setDefaultAnswer(result.data);
                }
                else {
                }
            });
        }
    }, []);
    useEffect(function () {
        if (defaultAnswer) {
        }
        else {
            modalServiceSubject.next({
                title: "Yarışma bitti!",
                content: "Dünya Kupası Tahmin Yarışması sona ermiştir.",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    }, [defaultAnswer]);
    //20 kasım 19.00'da kampanyanın kapanması
    var myDate = new Date('November 20, 2022 19:00:00');
    function isTournamentStarted() {
        var today = new Date();
        if (today.getDate() == 20 && today.getMonth() == 10 && today.getHours() >= 19) {
            return true;
        }
        else {
            return false;
        }
    }
    setTimeout(isTournamentStarted, myDate - Date.now());
    //gruplarda takım seçimi
    var setListItem = function (index, itemIndex, group, name) {
        var element = document.getElementById(name);
        if (element) {
            element.disabled = true;
        }
        if (itemIndex[0].id == null) {
            setTeamChoice(function (oldObject) {
                var newObject = __assign({}, oldObject);
                // newObject[group][0].id = index;
                // newObject[group][0].name = name;
                newObject[group][0] = { id: index, name: name };
                return newObject;
            });
        }
        else if (itemIndex[1].id == null) {
            setTeamChoice(function (oldObject) {
                var newObject = __assign({}, oldObject);
                newObject[group][1] = { id: index, name: name };
                return newObject;
            });
        }
        else if (itemIndex[2].id == null) {
            setTeamChoice(function (oldObject) {
                var newObject = __assign({}, oldObject);
                newObject[group][2] = { id: index, name: name };
                return newObject;
            });
        }
        else if (itemIndex[3].id == null) {
            setTeamChoice(function (oldObject) {
                var newObject = __assign({}, oldObject);
                newObject[group][3] = { id: index, name: name };
                return newObject;
            });
        }
    };
    //maçların kazananı seçimi
    var chooseTeam = function (match, team) {
        var buttonElement = document.querySelectorAll(".match".concat(match.matchId));
        var matchElement = document.getElementById(match.matchGroup + team.name);
        buttonElement.forEach(function (item) {
            if (item.classList.contains('match-active')) {
                item.classList.remove('match-active');
            }
            if (matchElement) {
                matchElement.classList.add("match-active");
            }
        });
        setTeamChoice(function (oldObject) {
            var newObject = __assign({}, oldObject);
            // newObject['match' + match.matchId] = match.team1.id;
            newObject['match' + match.matchId] = { name: team.name, id: team.id };
            forceUpdate();
            return newObject;
        });
    };
    //tüm maçların listesi
    var matches = {
        data: [
            {
                matchId: 49,
                matchTitle: '49. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_a'][0] && teamChoice['group_a'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[0] ? (defaultAnswer.last_16.split(',')[0]) : teamChoice['group_a'][0] && teamChoice['group_a'][0].id,
                    placeholder: 'A Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_b'][1] && teamChoice['group_b'][1]['name'],
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[1] ? (defaultAnswer.last_16.split(',')[1]) : teamChoice['group_b'][1] && teamChoice['group_b'][1].id,
                    placeholder: 'B Grubu İkincisi'
                }
            },
            {
                matchId: 50,
                matchTitle: '50. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_c'][0] && teamChoice['group_c'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[2] ? (defaultAnswer.last_16.split(',')[2]) : teamChoice['group_c'][0] && teamChoice['group_c'][0].id,
                    placeholder: 'C Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_d'][1] && teamChoice['group_d'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[3] ? (defaultAnswer.last_16.split(',')[3]) : teamChoice['group_d'][1] && teamChoice['group_d'][1].id,
                    placeholder: 'D Grubu İkincisi'
                }
            },
            {
                matchId: 51,
                matchTitle: '51. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_b'][0] && teamChoice['group_b'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[4] ? (defaultAnswer.last_16.split(',')[4]) : teamChoice['group_b'][0] && teamChoice['group_b'][0].id,
                    placeholder: 'B Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_a'][1] && teamChoice['group_a'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[5] ? (defaultAnswer.last_16.split(',')[5]) : teamChoice['group_a'][1] && teamChoice['group_a'][1].id,
                    placeholder: 'A Grubu İkincisi'
                }
            },
            {
                matchId: 52,
                matchTitle: '52. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_d'][0] && teamChoice['group_d'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[6] ? (defaultAnswer.last_16.split(',')[6]) : teamChoice['group_d'][0] && teamChoice['group_d'][0].id,
                    placeholder: 'D Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_c'][1] && teamChoice['group_c'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[7] ? (defaultAnswer.last_16.split(',')[7]) : teamChoice['group_c'][1] && teamChoice['group_c'][1].id,
                    placeholder: 'C Grubu İkincisi'
                }
            },
            {
                matchId: 53,
                matchTitle: '53. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_e'][0] && teamChoice['group_e'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[8] ? (defaultAnswer.last_16.split(',')[8]) : teamChoice['group_e'][0] && teamChoice['group_e'][0].id,
                    placeholder: 'E Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_f'][1] && teamChoice['group_f'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[9] ? (defaultAnswer.last_16.split(',')[9]) : teamChoice['group_f'][1] && teamChoice['group_f'][1].id,
                    placeholder: 'F Grubu İkincisi'
                }
            },
            {
                matchId: 54,
                matchTitle: '54. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_g'][0] && teamChoice['group_g'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[10] ? (defaultAnswer.last_16.split(',')[10]) : teamChoice['group_g'][0] && teamChoice['group_g'][0].id,
                    placeholder: 'G Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_h'][1] && teamChoice['group_h'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[11] ? (defaultAnswer.last_16.split(',')[11]) : teamChoice['group_h'][1] && teamChoice['group_h'][1].id,
                    placeholder: 'H Grubu İkincisi'
                }
            },
            {
                matchId: 55,
                matchTitle: '55. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_f'][0] && teamChoice['group_f'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[12] ? (defaultAnswer.last_16.split(',')[12]) : teamChoice['group_f'][0] && teamChoice['group_f'][0].id,
                    placeholder: 'F Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_e'][1] && teamChoice['group_e'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[13] ? (defaultAnswer.last_16.split(',')[13]) : teamChoice['group_e'][1] && teamChoice['group_e'][1].id,
                    placeholder: 'E Grubu İkincisi'
                }
            },
            {
                matchId: 56,
                matchTitle: '56. Maç',
                matchGroup: 'round16',
                team1: {
                    name: teamChoice['group_h'][0] && teamChoice['group_h'][0].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[14] ? (defaultAnswer.last_16.split(',')[14]) : teamChoice['group_h'][0] && teamChoice['group_h'][0].id,
                    placeholder: 'H Grubu Kazananı'
                },
                team2: {
                    name: teamChoice['group_g'][1] && teamChoice['group_g'][1].name,
                    id: defaultAnswer && defaultAnswer.last_16.split(',')[15] ? (defaultAnswer.last_16.split(',')[15]) : teamChoice['group_g'][1] && teamChoice['group_g'][1].id,
                    placeholder: 'G Grubu İkincisi'
                }
            },
            {
                matchId: 57,
                matchTitle: '57. Maç',
                matchGroup: 'quarterFinals',
                team1: {
                    name: teamChoice['match49'] && teamChoice['match49'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[0] ? (defaultAnswer.quarter.split(',')[0]) : teamChoice['match49'] && teamChoice['match49'].id,
                    placeholder: '49. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match50'] && teamChoice['match50'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[1] ? (defaultAnswer.quarter.split(',')[1]) : teamChoice['match50'] && teamChoice['match50'].id,
                    placeholder: '50. Maç Kazananı'
                }
            },
            {
                matchId: 58,
                matchTitle: '58. Maç',
                matchGroup: 'quarterFinals',
                team1: {
                    name: teamChoice['match53'] && teamChoice['match53'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[2] ? (defaultAnswer.quarter.split(',')[2]) : teamChoice['match53'] && teamChoice['match53'].id,
                    placeholder: '53. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match54'] && teamChoice['match54'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[3] ? (defaultAnswer.quarter.split(',')[3]) : teamChoice['match54'] && teamChoice['match54'].id,
                    placeholder: '54. Maç Kazananı'
                }
            },
            {
                matchId: 59,
                matchTitle: '59. Maç',
                matchGroup: 'quarterFinals',
                team1: {
                    name: teamChoice['match51'] && teamChoice['match51'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[4] ? (defaultAnswer.quarter.split(',')[4]) : teamChoice['match51'] && teamChoice['match51'].id,
                    placeholder: '51. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match52'] && teamChoice['match52'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[5] ? (defaultAnswer.quarter.split(',')[5]) : teamChoice['match52'] && teamChoice['match52'].id,
                    placeholder: '52. Maç Kazananı'
                }
            },
            {
                matchId: 60,
                matchTitle: '60. Maç',
                matchGroup: 'quarterFinals',
                team1: {
                    name: teamChoice['match55'] && teamChoice['match55'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[6] ? (defaultAnswer.quarter.split(',')[6]) : teamChoice['match55'] && teamChoice['match55'].id,
                    placeholder: '55. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match56'] && teamChoice['match56'].name,
                    id: defaultAnswer && defaultAnswer.quarter.split(',')[7] ? (defaultAnswer.quarter.split(',')[7]) : teamChoice['match56'] && teamChoice['match56'].id,
                    placeholder: '56. Maç Kazananı'
                }
            },
            {
                matchId: 61,
                matchTitle: '61. Maç',
                matchGroup: 'semiFinals',
                team1: {
                    name: teamChoice['match57'] && teamChoice['match57'].name,
                    id: defaultAnswer && defaultAnswer.half.split(',')[0] ? (defaultAnswer.half.split(',')[0]) : teamChoice['match57'] && teamChoice['match57'].id,
                    placeholder: '57. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match58'] && teamChoice['match58'].name,
                    id: defaultAnswer && defaultAnswer.half.split(',')[1] ? (defaultAnswer.half.split(',')[1]) : teamChoice['match58'] && teamChoice['match58'].id,
                    placeholder: '58. Maç Kazananı'
                }
            },
            {
                matchId: 62,
                matchTitle: '62. Maç',
                matchGroup: 'semiFinals',
                team1: {
                    name: teamChoice['match59'] && teamChoice['match59'].name,
                    id: defaultAnswer && defaultAnswer.half.split(',')[2] ? (defaultAnswer.half.split(',')[2]) : teamChoice['match59'] && teamChoice['match59'].id,
                    placeholder: '59. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match60'] && teamChoice['match60'].name,
                    id: defaultAnswer && defaultAnswer.half.split(',')[3] ? (defaultAnswer.half.split(',')[3]) : teamChoice['match60'] && teamChoice['match60'].id,
                    placeholder: '60. Maç Kazananı'
                }
            },
            {
                matchId: 64,
                matchTitle: '64. Maç',
                matchGroup: 'final',
                team1: {
                    name: teamChoice['match61'] && teamChoice['match61'].name,
                    id: defaultAnswer && defaultAnswer.final.split(',')[0] ? (defaultAnswer.final.split(',')[0]) : teamChoice['match61'] && teamChoice['match61'].id,
                    placeholder: '61. Maç Kazananı'
                },
                team2: {
                    name: teamChoice['match62'] && teamChoice['match62'].name,
                    id: defaultAnswer && defaultAnswer.final.split(',')[1] ? (defaultAnswer.final.split(',')[1]) : teamChoice['match62'] && teamChoice['match62'].id,
                    placeholder: '62. Maç Kazananı'
                }
            },
            {
                matchId: 65,
                matchTitle: '65.Maç ',
                matchGroup: 'winner',
                team1: {
                    name: teamChoice['match64'] && teamChoice['match64'].name,
                    id: defaultAnswer && defaultAnswer.winner ? (defaultAnswer.winner) : teamChoice['match64'] && teamChoice['match64'].id,
                    placeholder: '64. Maç Kazananı'
                },
                team2: { name: '', id: '', placeholder: '' }
            }
        ]
    };
    var sendChoices = function () {
        if (isTournamentStarted()) {
            modalServiceSubject.next({
                title: "Yarışma bitti!",
                content: "Dünya Kupası Tahmin Yarışması sona ermiştir.",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
        else {
            if (member.id) {
                var sendData = {
                    type: 'set',
                    token: Storage.get('token'),
                    group_a: teamChoice['group_a'].map(function (x) { return Number(x.id); }),
                    group_b: teamChoice['group_b'].map(function (x) { return Number(x.id); }),
                    group_c: teamChoice['group_c'].map(function (x) { return Number(x.id); }),
                    group_d: teamChoice['group_d'].map(function (x) { return Number(x.id); }),
                    group_e: teamChoice['group_e'].map(function (x) { return Number(x.id); }),
                    group_f: teamChoice['group_f'].map(function (x) { return Number(x.id); }),
                    group_g: teamChoice['group_g'].map(function (x) { return Number(x.id); }),
                    group_h: teamChoice['group_h'].map(function (x) { return Number(x.id); }),
                    last_16: [
                        teamChoice['group_a'][0].id, teamChoice['group_b'][1].id,
                        teamChoice['group_c'][0].id, teamChoice['group_d'][1].id,
                        teamChoice['group_b'][0].id, teamChoice['group_a'][1].id,
                        teamChoice['group_d'][0].id, teamChoice['group_c'][1].id,
                        teamChoice['group_e'][0].id, teamChoice['group_f'][1].id,
                        teamChoice['group_g'][0].id, teamChoice['group_h'][1].id,
                        teamChoice['group_f'][0].id, teamChoice['group_e'][1].id,
                        teamChoice['group_h'][0].id, teamChoice['group_g'][1].id
                    ].map(function (i) { return Number(i); }),
                    quarter: [
                        teamChoice['match49'].id, teamChoice['match50'].id,
                        teamChoice['match53'].id, teamChoice['match54'].id,
                        teamChoice['match51'].id, teamChoice['match52'].id,
                        teamChoice['match55'].id, teamChoice['match56'].id
                    ].map(function (i) { return Number(i); }),
                    half: [teamChoice['match57'].id, teamChoice['match58'].id, teamChoice['match59'].id, teamChoice['match60'].id].map(function (i) { return Number(i); }),
                    final: [teamChoice['match61'].id, teamChoice['match62'].id].map(function (i) { return Number(i); }),
                    winner: Number(teamChoice['match64'].id)
                };
                // Object.values(sendData).map((x:any)=>{
                //     if(x == 0){
                //         console.log(x,'bu null')
                //     }else{
                //         console.log('null yok')
                //     }
                // })
                Api.start('post', env.misc_api + '/product/api/world_cup/', sendData, true).then(function (result) {
                    if (result.status) {
                        modalServiceSubject.next({
                            title: "Teşekkürler!",
                            content: 'Yarışma cevaplarınız kaydedildi!',
                            confirm: {
                                sure: {
                                    label: 'Şimdi Oyna!',
                                    action: function () {
                                        navigate('/iddaa/futbol/uzunvadeli/1382,1383,1384,1385,1386,1387,1388,1389,27070');
                                    }
                                },
                            },
                            timeout: 0
                        });
                        var subContainer = document.querySelectorAll(".firework");
                        subContainer.forEach(function (item) {
                            item.style.display = 'block';
                            setTimeout(function () { return item.style.display = 'none'; }, 9000);
                        });
                    }
                    else {
                        modalServiceSubject.next({
                            title: "Uyarı!",
                            content: result.message,
                            confirm: {
                                cancel: { label: 'Tamam' }
                            },
                            timeout: 0
                        });
                    }
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: 'Kampanyaya katılmak için giriş yapmanız gerekiyor!',
                    confirm: {
                        action: function () {
                            navigate('/giris-yap');
                        }
                    },
                    timeout: 0
                });
            }
        }
    };
    function setIcon(iconName) {
        var newName;
        var countryObj = {
            'USA': "United_States",
            'Iran': "Iran_Islamic_Republic_of",
            'South Korea': "Korea_Republic_Of",
            'Saudi Arabia': "Saudi_Arabia",
            'Costa Rica': "Costa_Rica",
        };
        if (countryObj[iconName]) {
            newName = countryObj[iconName];
            return newName;
        }
        else {
            return iconName;
        }
    }
    function refreshChoices(groups) {
        var buttonElement;
        matchIndexesAffectedByGroups[groups.id].map(function (insideMatch) {
            buttonElement = document.querySelectorAll(".match".concat(insideMatch));
            if (buttonElement) {
                buttonElement.forEach(function (item) {
                    if (item.classList.contains('match-active')) {
                        item.classList.remove('match-active');
                    }
                });
            }
            groups.teams.map(function (y) {
                var element = document.getElementById(y.countryName);
                if (element) {
                    element.disabled = false;
                }
            });
        });
        setTeamChoice(function (oldObject) {
            var newObject = __assign({}, oldObject);
            newObject[groups.id] = [{ id: null, name: null }, { id: null, name: null }, { id: null, name: null }, {
                    id: null,
                    name: null
                }];
            matchIndexesAffectedByGroups[groups.id].map(function (x) {
                return (newObject['match' + x] = { id: null, name: null });
            });
            return newObject;
        });
    }
    //kullanıcının daha önceden kaydettiği bir kayıt var mı ve login olmuş mu
    if (defaultAnswer && member.id) {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", __assign({ className: "" }, { children: "D\u00DCNYA KUPASI 2022" }), void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("span", { children: "Yar\u0131\u015Fmaya daha \u00F6nce kat\u0131ld\u0131ysan\u0131z se\u00E7mi\u015F oldu\u011Funuz s\u0131ralamay\u0131 a\u015Fa\u011F\u0131da g\u00F6rebilirsiniz." }, void 0), _jsx("div", __assign({ className: "row" }, { children: groupTeams.data ? groupTeams.data.map(function (groups, key1) {
                                return (_jsxs("div", __assign({ className: "col-xl-3 col-md-6 col-sm-12  border-right-line" }, { children: [_jsx("div", __assign({ className: "row justify-content-center border-bottom-line m-0 w-100 p-10-20" }, { children: _jsx("span", __assign({ className: 'fw-bold' }, { children: groups.title }), void 0) }), void 0), teamChoice && teamChoice[groups.id] ? _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "row col d-flex m-0 p-0" }, { children: groups.teams ? groups.teams.map(function (team, key) {
                                                        return (_jsx("div", __assign({ className: 'col-3 p-0 d-flex justify-content-center mt-2' }, { children: _jsxs("button", __assign({ className: 'btn btn-small p-events-none', value: team.id, disabled: true, id: team.countryName }, { children: [_jsx("img", { width: 13, height: 13, src: "https://cdn2.oley.com/images/country_icons_circle/".concat(setIcon(team.countryName), ".png") }, void 0), "\u00A0  ", team.short] }), void 0) }), key + 'ktrbtndefault'));
                                                    }) : null }), void 0), _jsx("ul", __assign({ className: 'list-groups' }, { children: defaultAnswer && defaultAnswer[groups.id] ?
                                                        defaultAnswer[groups.id].split(',').map(function (item, index) {
                                                            return (_jsx("li", { children: _jsxs("div", __assign({ className: 'ktr-list-group-item' }, { children: [_jsxs("span", __assign({ className: 'my-auto pl-3' }, { children: [index + 1, " \u00A0"] }), void 0), groups.teams ? groups.teams.map(function (team, key) {
                                                                            if (team.id == item) {
                                                                                return (_jsxs("div", { children: [item && item.name !== null ?
                                                                                            _jsx("img", { width: 23, height: 23, src: "https://cdn2.oley.com/images/country_icons_circle/".concat(setIcon(team.countryName), ".png") }, void 0) : _jsx(_Fragment, {}, void 0), "\u00A0", _jsx("span", __assign({ className: 'my-auto' }, { children: item && item.name == null ? team.trCountryName : '' }), 'imgcountry' + key)] }, 'defaultaswgroup' + key));
                                                                            }
                                                                            else {
                                                                                return null;
                                                                            }
                                                                        }) : null] }), index + 'ktrlistlist') }, index + 'ktrlist3'));
                                                        }) : '' }), void 0)] }, void 0) : ''] }), key1 + 'ktrdefaultanswer'));
                            }) : '' }), void 0), _jsxs("div", __assign({ className: "mt-2 matches" }, { children: [_jsx("div", __assign({ className: "row border-top-line" }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Son 16" }), void 0) }), void 0), _jsx("div", __assign({ className: "row" }, { children: matches ? matches.data.filter(function (x) { return x.matchId !== 65; }).map(function (match, key) {
                                        return (_jsxs("div", __assign({ className: 'd-contents', style: { display: 'contents !important' } }, { children: [match.matchId == 57 ?
                                                    _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "\u00C7eyrek Final" }), void 0) }), void 0)
                                                    : match.matchId == 61 ?
                                                        _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Yar\u0131 Final" }), void 0) }), void 0)
                                                        : match.matchId == 64 ? _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Final" }), void 0) }), void 0) : null, _jsxs("div", __assign({ className: match.matchGroup == 'round16' || match.matchGroup == 'quarterFinals' ?
                                                        ' col-md-3 col-sm-12 p-3' : match.matchGroup == 'semiFinals' ? 'col-6  p-3 ' : 'col-12  p-3' }, { children: [_jsx("span", __assign({ className: 'd-flex justify-content-center my-1' }, { children: match.matchTitle }), void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-6 pr-0" }, { children: _jsx("button", __assign({ disabled: true, className: 'btn w-100 px-0 height-100 border-left p-events-none match' + match.matchId }, { children: match.team1.id && match.team1.id.length > 0 ?
                                                                            _jsx("div", { children: match.team1.id && match.team1.id.length > 0 ? groupTeams.data.map(function (groups) {
                                                                                    return (groups.teams.map(function (team) {
                                                                                        if (team.id === Number(match.team1.id)) {
                                                                                            return (_jsxs("div", { children: [_jsx("img", { width: 70, height: 50, src: "https://cdn2.oley.com/images/flags_new/".concat(setIcon(team.countryName), ".png") }, void 0), _jsx("br", {}, void 0), " ", _jsx("span", { children: team.trCountryName }, key + 'matchteam11')] }, 'defaultaswmatch' + key));
                                                                                        }
                                                                                        else {
                                                                                            return null;
                                                                                        }
                                                                                    }));
                                                                                }) : null }, key + 'dfltmatchteam1') : match.team1.placeholder }), void 0) }), void 0), _jsx("div", __assign({ className: "col-6 pl-0" }, { children: _jsx("button", __assign({ disabled: true, className: 'btn w-100 px-0 height-100 border-right p-events-none match' + match.matchId }, { children: match.team2.id && match.team2.id.length > 0 ?
                                                                            _jsx("div", { children: match.team2.id && match.team2.id.length > 0 ? groupTeams.data.map(function (groups) {
                                                                                    return (groups.teams.map(function (team, key) {
                                                                                        if (team.id === Number(match.team2.id)) {
                                                                                            return (_jsxs("span", { children: [_jsx("img", { width: 70, height: 50, src: "https://cdn2.oley.com/images/flags_new/".concat(setIcon(team.countryName), ".png") }, void 0), _jsx("br", {}, void 0), _jsx("span", { children: team.trCountryName }, team.trCountryName + key)] }, 'defaultanswer' + key));
                                                                                        }
                                                                                        else {
                                                                                            return null;
                                                                                        }
                                                                                    }));
                                                                                }) : null }, key + 'dfltmatchteam2') : match.team2.placeholder }), void 0) }), void 0)] }), void 0)] }), void 0)] }), '_matchesdefault' + key));
                                    }) : _jsx(_Fragment, {}, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: 'row justify-content-center  mt-3 border-top-line' }, { children: matches && matches.data.filter(function (x) { return x.matchId == 65; }) ? matches.data.filter(function (x) { return x.matchId == 65; }).map(function (y, key) {
                                return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "col-12 d-flex justify-content-center" }, { children: [_jsx("h2", __assign({ className: 'card-title' }, { children: "KAZANAN" }), void 0), " ", _jsx("br", {}, void 0)] }), void 0), _jsx("div", __assign({ className: "col-12 d-flex justify-content-center mt-2 mb-3" }, { children: _jsxs("div", __assign({ className: "subContainer background" }, { children: [_jsxs("span", __assign({ className: "icon fw-bold text-center" }, { children: [y.team1.id && y.team1.id.length > 0 ? groupTeams.data.map(function (groups) {
                                                                return (groups.teams.map(function (team, key1) {
                                                                    if (team.id == Number(y.team1.id)) {
                                                                        return (_jsxs("span", { children: [_jsxs("span", __assign({ className: "icon fw-bold text-center" }, { children: [" ", team.trCountryName] }), team.trCountryName + key1 + 'team'), _jsx("br", {}, void 0), team.id ? _jsxs(_Fragment, { children: [_jsx("img", { width: 70, height: 50, src: " https://cdn2.oley.com/images/flags_new/".concat(setIcon(team.countryName), ".png") }, void 0), " "] }, void 0)
                                                                                    : null] }, '3defaultanswer' + key));
                                                                    }
                                                                    else {
                                                                        return _jsx(_Fragment, {}, void 0);
                                                                    }
                                                                }));
                                                            }) : null, _jsx("br", {}, void 0)] }), void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0)] }), void 0) }), void 0)] }, 'kazanandflt' + key));
                            }) : '' }), void 0)] }), void 0)] }), void 0));
    }
    // kullanıcı login olmuş mu ve yarışma devam ediyor mu
    if (member.id) {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("h2", __assign({ className: "card-title" }, { children: "D\u00DCNYA KUPASI 2022" }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("div", __assign({ className: "row" }, { children: groupTeams.data ? groupTeams.data.map(function (groups, key1) {
                                return (_jsxs("div", __assign({ className: "col-xl-3 col-md-6 col-sm-12  border-right-line" }, { children: [_jsxs("div", __assign({ className: "row justify-content-center border-bottom-line m-0 w-100 p-10-20" }, { children: [_jsx("span", __assign({ className: 'fw-bold ml-auto' }, { children: groups.title }), void 0), _jsx("button", __assign({ className: 'btn btn-small ml-auto', style: { background: 'var(--color-bg-medium)' }, onClick: function () { return refreshChoices(groups); } }, { children: _jsx("img", { width: 15, height: 15, src: refreshIcon, alt: "" }, void 0) }), void 0)] }), void 0), teamChoice && teamChoice[groups.id] ? _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "row col d-flex m-0 p-0" }, { children: groups.teams ? groups.teams.map(function (team, key) {
                                                        return (_jsx("div", __assign({ className: 'col-3 p-0 d-flex justify-content-center mt-2' }, { children: _jsxs("button", __assign({ className: 'btn btn-small', value: team.id, id: team.countryName, onClick: function (e) { return setListItem(e.target.value, teamChoice[groups.id], groups.id, team.countryName); } }, { children: [_jsx("img", { className: 'p-events-none', width: 13, height: 13, src: "https://cdn2.oley.com/images/country_icons_circle/".concat(setIcon(team.countryName), ".png") }, void 0), "\u00A0  ", team.short] }), void 0) }), key + 'ktrbtn'));
                                                    }) : null }), void 0), _jsx("ul", __assign({ className: 'list-groups cursor-pointer' }, { children: teamChoice && teamChoice[groups.id] ?
                                                        teamChoice[groups.id].map(function (item, index) {
                                                            return (_jsx("li", { children: _jsxs("div", __assign({ className: 'ktr-list-group-item' }, { children: [_jsxs("span", __assign({ className: 'my-auto pl-3' }, { children: [index + 1, " \u00A0"] }), void 0), item && item.name !== null ?
                                                                            _jsx("img", { width: 23, height: 23, src: "https://cdn2.oley.com/images/country_icons_circle/".concat(setIcon(item.name), ".png") }, void 0) : _jsx(_Fragment, {}, void 0), "\u00A0", groups.teams ? groups.teams.map(function (team, key) {
                                                                            if (team.id == item.id) {
                                                                                return (_jsx("span", __assign({ className: 'my-auto' }, { children: item && item.name !== null ? team.trCountryName : '' }), 'imgcountry' + key));
                                                                            }
                                                                            else {
                                                                                return null;
                                                                            }
                                                                        }) : null] }), index + 'ktrlistlist') }, index + 'ktrlist1'));
                                                        }) : '' }), void 0)] }, void 0) : ''] }), key1 + 'ktr'));
                            }) : '' }), void 0), _jsxs("div", __assign({ className: "mt-2 matches" }, { children: [_jsx("div", __assign({ className: "row border-top-line" }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Son 16" }), void 0) }), void 0), _jsx("div", __assign({ className: "row" }, { children: matches ? matches.data.filter(function (x) { return x.matchId !== 65; }).map(function (match, key) {
                                        return (_jsxs("div", __assign({ className: 'd-contents', style: { display: 'contents !important' } }, { children: [match.matchId == 57 ?
                                                    _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "\u00C7eyrek Final" }), void 0) }), void 0)
                                                    : match.matchId == 61 ?
                                                        _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Yar\u0131 Final" }), void 0) }), void 0)
                                                        : match.matchId == 64 ? _jsx("span", __assign({ className: 'w-100 mt-3 border-top-line' }, { children: _jsx("h2", __assign({ className: 'card-title' }, { children: "Final" }), void 0) }), void 0) : null, _jsxs("div", __assign({ className: match.matchGroup == 'round16' || match.matchGroup == 'quarterFinals' ? ' col-md-3 col-sm-12 p-3' : match.matchGroup == 'semiFinals' ? 'col-6  p-3 ' : 'col-12  p-3' }, { children: [_jsx("span", __assign({ className: 'd-flex justify-content-center my-1' }, { children: match.matchTitle }), void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-6 pr-0" }, { children: _jsx("button", __assign({ disabled: match.team1.name == undefined || match.team1.name.length <= 0, className: 'btn w-100 px-0 height-100 border-left match' + match.matchId, id: match.matchGroup + match.team1.name, onClick: function () { return chooseTeam(match, match.team1); } }, { children: match.team1.name && match.team1.name.length > 0 ?
                                                                            _jsxs("div", { children: [_jsx("img", { width: 70, height: 50, src: "https://cdn2.oley.com/images/flags_new/".concat(setIcon(match.team1.name), ".png") }, void 0), _jsx("br", {}, void 0), match.team1.name && match.team1.name.length > 0 ? groupTeams.data.map(function (groups) {
                                                                                        return (groups.teams.map(function (team) {
                                                                                            if (team.countryName === match.team1.name) {
                                                                                                return (_jsx("span", { children: team.trCountryName }, key + 'matchteam11'));
                                                                                            }
                                                                                            else {
                                                                                                return null;
                                                                                            }
                                                                                        }));
                                                                                    }) : null] }, key + 'matchteam1') : match.team1.placeholder }), void 0) }), void 0), _jsx("div", __assign({ className: "col-6 pl-0" }, { children: _jsx("button", __assign({ disabled: match.team2.name == undefined || match.team2.name.length <= 0, className: 'btn w-100 px-0 height-100 border-right match' + match.matchId, id: match.matchGroup + match.team2.name, onClick: function () { return chooseTeam(match, match.team2); } }, { children: match.team2.name && match.team2.name.length > 0 ?
                                                                            _jsxs("div", { children: [_jsx("img", { width: 70, height: 50, src: "https://cdn2.oley.com/images/flags_new/".concat(setIcon(match.team2.name), ".png") }, void 0), _jsx("br", {}, void 0), match.team2.name && match.team2.name.length > 0 ? groupTeams.data.map(function (groups) {
                                                                                        return (groups.teams.map(function (team, key) {
                                                                                            if (team.countryName === match.team2.name) {
                                                                                                return (_jsx("span", { children: team.trCountryName }, team.trCountryName + key));
                                                                                            }
                                                                                            else {
                                                                                                return null;
                                                                                            }
                                                                                        }));
                                                                                    }) : null] }, key + 'matchteam2') : match.team2.placeholder }), void 0) }), void 0)] }), void 0)] }), void 0)] }), '_matches' + key));
                                    }) : _jsx(_Fragment, {}, void 0) }), void 0), _jsx("div", __assign({ className: 'row justify-content-center  mt-3 border-top-line' }, { children: matches && matches.data.filter(function (x) { return x.matchId == 65; }) ? matches.data.filter(function (x) { return x.matchId == 65; }).map(function (y, key) {
                                        return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "col-12 d-flex justify-content-center" }, { children: [_jsx("h2", __assign({ className: 'card-title' }, { children: "KAZANAN" }), void 0), " ", _jsx("br", {}, void 0)] }), void 0), _jsx("div", __assign({ className: "col-12 d-flex justify-content-center mt-2 mb-3" }, { children: _jsxs("div", __assign({ className: "subContainer background" }, { children: [_jsxs("span", __assign({ className: "icon fw-bold text-center" }, { children: ["\u00A0", y.team1.name && y.team1.name.length > 0 ? groupTeams.data.map(function (groups) {
                                                                        return (groups.teams.map(function (team, key1) {
                                                                            if (team.countryName === y.team1.name) {
                                                                                return (_jsx(_Fragment, { children: _jsx("span", __assign({ className: "icon fw-bold" }, { children: team.trCountryName }), team.trCountryName + key1 + 'team') }, void 0));
                                                                            }
                                                                            else {
                                                                                return _jsx(_Fragment, {}, void 0);
                                                                            }
                                                                        }));
                                                                    }) : null, _jsx("br", {}, void 0), y.team1.name ? _jsxs(_Fragment, { children: [_jsx("img", { width: 80, height: 60, src: " https://cdn2.oley.com/images/flags_new/".concat(setIcon(y.team1.name), ".png") }, void 0), " "] }, void 0)
                                                                        : null] }), void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0), _jsx("div", { className: "firework" }, void 0)] }), void 0) }), void 0)] }, 'kazananktr' + key));
                                    }) : '' }), void 0), _jsx("div", __assign({ className: "row" }, { children: _jsx("button", __assign({ disabled: true, className: 'button m-auto d-flex justify-content-center btn btn-primary' }, { children: "SE\u00C7\u0130MLER\u0130M\u0130 KAYDET" }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
    }
    else {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("h2", __assign({ className: "card-title my-5 text-center" }, { children: "Yar\u0131\u015Fmay\u0131 g\u00F6r\u00FCnt\u00FClemek ve kat\u0131lmak i\u00E7in giri\u015F yapmal\u0131s\u0131n\u0131z!" }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center mb-5" }, { children: _jsx("button", __assign({ className: 'btn btn-warning btnLogin', onClick: function () { return navigate('/giris-yap'); } }, { children: "G\u0130R\u0130\u015E YAP" }), void 0) }), void 0)] }), void 0));
    }
};
export default PredictionGame;
