var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ApiService } from "../../../lib/http.service";
import { eventMapping, eventUpdateSubject, marketIdMapping } from '../../../store/sportsbook.store';
import { env } from "../../../definitions/global.vars";
import { getDateHumanize, useForceUpdate } from "../../../lib/misc.functions";
import { Link, useNavigate } from "react-router-dom";
import { addToBetSlip } from "../../../services/betslip.services";
import Lock from "../../../assets/feather/lock.svg";
import { LiveScoreStatusType } from "../../../definitions/constants/event-status-type.constant";
import { betSlipActionSubject } from "../../../store/betslip.store";
var updateTimer;
export var mostPlayedOdds = [];
export default function MostPlayed() {
    var forceUpdate = useForceUpdate();
    var apiService = new ApiService();
    var data = [];
    var loadApi = function () {
        (function () {
            return __awaiter(this, void 0, void 0, function () {
                var mostPlayed;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            mostPlayed = env.misc_api + '/misc/api/get-most-played-odds/';
                            return [4 /*yield*/, apiService
                                    .start('get', mostPlayed, null, false)
                                    .then(function (response) {
                                    if (response.status == true) {
                                        response.data.forEach(function (item) {
                                            var event = eventMapping[item.event_id.toString()];
                                            if (event && Number(event.eventType) === 1) {
                                                var market = void 0;
                                                try {
                                                    market = marketIdMapping[item.event_id][item.market_id];
                                                }
                                                catch (e) {
                                                    market = null;
                                                }
                                                if (market) {
                                                    var outcome = market.marketOutcome.find(function (m) {
                                                        return m.outcomeNo === item.outcome_no;
                                                    });
                                                    data.push({
                                                        event: event,
                                                        market: market,
                                                        outcome: outcome,
                                                        count: item.count,
                                                    });
                                                }
                                            }
                                        });
                                        Object.assign(mostPlayedOdds, data.slice(0, 10));
                                        forceUpdate();
                                    }
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        })();
    };
    useEffect(function () {
        loadApi();
    }, []);
    return (_jsxs("div", __assign({ className: "widget card ml-1 mr-1" }, { children: [_jsx("div", __assign({ className: "header" }, { children: _jsx("h2", { children: "En \u00C7ok Oynananlar" }, void 0) }), void 0), _jsx("div", __assign({ className: "body" }, { children: mostPlayedOdds.map(function (item, key) {
                    return (_jsx("div", __assign({ className: "event-card list " + ((key %= 2) === 0 ? ' dark ' : ''), style: { display: "flex" } }, { children: _jsx(EventRow, { item: item, event: item.event }, void 0) }), "mostPlayedOdds-".concat(key, "-").concat(item.market.marketId, "-").concat(item.outcome.outcomeNo)));
                }) }), void 0)] }), void 0));
}
function EventRow(props) {
    var event = props.event;
    var item = props.item;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (typeof events === 'object' && event) {
                if (['toggle', 'remove'].includes(events.action)) {
                    if (events.betSlip.sportEvent) {
                        if (events.betSlip.sportEvent.eventId === event.eventId) {
                            forceUpdate();
                        }
                    }
                }
                else if (events.action === 'clear') {
                    if (events.betSlip.includes(event.eventId)) {
                        forceUpdate();
                    }
                }
            }
        });
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (event && events.includes(event.eventId)) {
                clearTimeout(updateTimer);
                updateTimer = setTimeout(function () {
                    forceUpdate();
                }, 300);
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
            eventUpdateSubject_subscription.unsubscribe();
            clearTimeout(updateTimer);
        };
    }, []);
    if (event.liveScoreStatus && event.matchPlayTime && event.currentScore) {
        var time_statuses = ["520", "521", "522", "523", "700"];
        if (time_statuses.includes(event.liveScoreStatus.toString())) {
            event.minute = event.matchPlayTime + '.dk';
        }
        else {
            event.minute = LiveScoreStatusType[Number(event.liveScoreStatus)];
        }
    }
    if (Number(item.market.marketStatus) > 1 ||
        Number(item.market.marketStatus) === 0 ||
        Number(item.outcome.fixedOddsWeb) === 1 ||
        (Number(item.event.bettingStatus) === 0 && Number(item.event.bettingPhase) === 1)) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "event-date hidden-sm", style: { minWidth: '45px', width: '105px' } }, { children: [Number(event.bettingPhase) !== 0 && event.currentScore ?
                        _jsx("span", __assign({ className: "mbs fw-bold text-center" }, { children: event.currentScore }), void 0) :
                        _jsx("span", __assign({ className: "date mt-0" }, { children: getDateHumanize(event.eventDate, 'short') }), void 0), Number(event.bettingPhase) !== 0 && event.matchPlayTime ? _jsx("span", __assign({ className: "minute" }, { children: event.minute }), void 0) :
                        _jsx("span", { children: event.eventDate.split("T")[1].substring(0, 5) }, void 0)] }), void 0), _jsxs(Link, __assign({ to: '/iddaa/' + event.sportSlug + '/tumu/' + event.competitionId + '/' + event.eventId, className: "participants-labels w-100 pl-sm-0", style: { display: "block" } }, { children: [_jsx("div", __assign({ className: "competition nowrap", style: { lineHeight: '21px', maxWidth: 230 } }, { children: event.eventType === '2' ? event.eventName.replace(item.market.marketName, '').replace(' - ', '') : event.eventName }), void 0), _jsx("div", __assign({ className: "participants" }, { children: _jsxs("div", __assign({ className: "labels" }, { children: [_jsx("div", __assign({ className: "mbs mbs-".concat(event.minCombiCount) }, { children: event.minCombiCount }), void 0), _jsxs("span", { children: [item.market.marketName, " ( ", item.count, " oynanma )"] }, void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "highlighted-odds w-75 mt-0" }, { children: _jsxs("div", __assign({ style: { display: "flex" } }, { children: [_jsx("div", __assign({ className: "group" }, { children: _jsxs("button", __assign({ className: "btn btn-odds ".concat(item.outcome.isUpdated, " added-").concat(item.outcome.isSelected, " ") + (item.market.marketStatus < 0 ? 'locked' : ''), onClick: function () {
                                    addToBetSlip(event, item.market, item.outcome);
                                } }, { children: [_jsxs("div", __assign({ className: "outcome-name nowrap" }, { children: [item.outcome.outcomeName, " ", item.market.specialOddsValue ? item.market.specialOddsValue : null] }), void 0), _jsx("div", __assign({ className: "ratio text-right" }, { children: item.market.marketStatus < 0 ? _jsx("img", { src: Lock }, void 0) : _jsx(_Fragment, { children: Number(item.outcome.fixedOddsWeb) > 1 && item.market.marketStatus !== 0 && item.market.marketStatus < 2 ? Number(item.outcome.fixedOdds).toFixed(2) : '-' }, void 0) }), void 0)] }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0));
}
