var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import './assets/css/bootstrap/bootstrap-reboot.min.css';
import './assets/css/bootstrap/bootstrap-grid.min.css';
import './assets/css/style.css';
import './assets/css/media968.css';
import './assets/css/sporticon.css';
import AppRoutes from './app/Routes';
import SportsBookService, { getSportSummery } from "./services/sportsbook.services";
import StaticPagesService from "./services/static.pages.service";
import { AuthContext, loginSubject, member } from "./store/login.store";
import { restoreLogin } from "./services/login.services";
import ModalService from "./components/modal/modal";
import Modal from "react-modal";
import { isOffline, isOnline } from "./lib/misc.functions";
import OneSignal from 'react-onesignal';
import { ApiService } from "./lib/http.service";
import { env } from "./definitions/global.vars";
import { Storage } from "./lib/localstorege.service";
export var inProgress = { state: false, };
var width = window.innerWidth;
var isMobileView = width < 986 ? true : false;
var timer_sportsSummery;
function App() {
    var _a = useState(false), isAuthenticated = _a[0], setIsAuthenticated = _a[1];
    // useEffect(() => {
    //   try {
    //     Sentry.init({
    //       dsn: "https://b944e38d664ebcbd0256ad8164f1beb5@o4506263066116096.ingest.sentry.io/4506263068278784",
    //       integrations: [
    //         new Sentry.BrowserTracing({
    //           // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //           tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    //         }),
    //         new Sentry.Replay({
    //           networkDetailAllowUrls: [window.location.origin, "api.oley.com",
    //             /^https:\/\/api\.oley\.com/,],
    //           networkRequestHeaders: ["Cache-Control"],
    //           networkResponseHeaders: ["Referrer-Policy"],
    //         }),
    //       ],
    //       // Performance Monitoring
    //       tracesSampleRate: 1.0, // Capture 100% of the transactions
    //       // Session Replay
    //       replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //       replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    //
    //     });
    //   } catch (e) {
    //
    //   }
    // }, []);
    useEffect(function () {
        timer_sportsSummery = setInterval(function () {
            getSportSummery();
        }, 120000);
        return function () {
            clearInterval(timer_sportsSummery);
        };
    }, []);
    useEffect(function () {
        if (member && member.id) {
            OneSignal.init({ appId: "13309847-d3cf-4bc3-9960-ad7284f67596" }).then(function () {
                OneSignal.setExternalUserId(member.id.toString()).then(function (res) {
                    console.log("OneSignal.setExternalUserId", res);
                }).finally(function () {
                    OneSignal.showSlidedownPrompt().then(function () {
                        OneSignal.getUserId().then(function (res) {
                            var os = "Unknown OS";
                            if (navigator.userAgent.indexOf("Win") != -1)
                                os = "Windows";
                            if (navigator.userAgent.indexOf("Mac") != -1)
                                os = "Macintosh";
                            if (navigator.userAgent.indexOf("Linux") != -1)
                                os = "Linux";
                            if (navigator.userAgent.indexOf("Android") != -1)
                                os = "Android";
                            if (navigator.userAgent.indexOf("like Mac") != -1)
                                os = "iOS";
                            var browserType = "Unknown Browser Type";
                            if (navigator.userAgent.indexOf("Safari") != -1)
                                browserType = "Safari";
                            if (navigator.userAgent.indexOf("Chrome") != -1)
                                browserType = "Chrome";
                            if (navigator.userAgent.indexOf("OPR") != -1)
                                browserType = "Opera";
                            if (navigator.userAgent.indexOf("Firefox") != -1)
                                browserType = "Firefox";
                            var deviceType = "Desktop";
                            if (navigator.appVersion.indexOf("Mobile") > -1)
                                deviceType = "Mobile";
                            var params = {
                                token: Storage.get('token'),
                                device_id: res,
                                device_type: deviceType,
                                device_os: os,
                                browser_os: browserType,
                            };
                            var api = new ApiService();
                            api.start('post', env.misc_api + '/member/api/add-member-device/', params, true)
                                .then(function (res) {
                                console.log(res);
                            });
                        });
                    });
                });
            });
        }
    }, [isAuthenticated]);
    useEffect(function () {
        window.addEventListener('resize', resizeWindow);
        return function () {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);
    useEffect(function () {
        window.addEventListener("online", isOnline, false);
        window.addEventListener("offline", isOffline, false);
        return function () {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);
    var resizeWindow = function () {
        var width = window.innerWidth;
        var nowIsMobileView = width < 986 ? true : false;
        if (isMobileView !== nowIsMobileView) {
            window.location.reload();
        }
    };
    useEffect(function () {
        var loginSubject_subscription;
        if (!isAuthenticated) {
            // eğer token varsa otomatik auth icin wss i restore etmek icin var.
            restoreLogin();
            loginSubject_subscription = loginSubject.subscribe(function (member) {
                if (member.id !== undefined && member.status === 1) {
                    setIsAuthenticated(true);
                }
            });
        }
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    function searchSosyoley() {
        if (document.activeElement ? document.activeElement.id == "SosyoSearch" : false) {
            document.getElementById("SearchList") ? document.getElementById("SearchList").style.display = "inline" : null;
        }
        else {
            document.getElementById("SearchList") ? document.getElementById("SearchList").style.display = "none" : null;
        }
    }
    // @ts-ignore
    Modal.setAppElement(document.getElementById('root'));
    return (_jsx("div", __assign({ id: 'root', onClick: function () { return searchSosyoley(); } }, { children: _jsx(AuthContext.Provider, __assign({ value: { isAuthenticated: isAuthenticated, setIsAuthenticated: setIsAuthenticated } }, { children: _jsx(StaticPagesService, { children: _jsx(SportsBookService, { children: _jsx(ModalService, { children: _jsx(AppRoutes, {}, void 0) }, void 0) }, void 0) }, void 0) }), void 0) }), void 0));
}
export default App;
