var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { RegisterApi } from "./register.api";
import { modalServiceSubject } from "../../components/modal/modal";
import { Link } from "react-router-dom";
import { GetCookies, initialLeftMenu, ServerDate, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
import { staticPagesList } from '../../store/static.pages.store';
import ValidationSmsModal from './widgets/validation.sms.modal';
import { loginMember, loginSubject } from "../../store/login.store";
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';
import { Storage } from "../../lib/localstorege.service";
var years = [];
var days = [];
var months = [
    { val: '01', name: 'Ocak' },
    { val: '02', name: 'Şubat' },
    { val: '03', name: 'Mart' },
    { val: '04', name: 'Nisan' },
    { val: '05', name: 'Mayıs' },
    { val: '06', name: 'Haziran' },
    { val: '07', name: 'Temmuz' },
    { val: '08', name: 'Ağustos' },
    { val: '09', name: 'Eylül' },
    { val: '10', name: 'Ekim' },
    { val: '11', name: 'Kasım' },
    { val: '12', name: 'Aralık' }
];
var today = ServerDate();
var yy = today.getFullYear() - 18;
for (var i = yy; i >= (yy - 70); i--) {
    years.push(i);
}
for (var j = 1; j < 32; j++) {
    days.push(j < 10 ? ("0" + j) : j.toString());
}
function openStaticDataModal(pageType) {
    var staticData = staticPagesList.find(function (x) { return x.type == pageType; }) || Storage.get('staticPagesList').find(function (x) { return x.type == pageType; }) || { name: '', body: '' };
    modalServiceSubject.next({
        title: staticData.name,
        content: staticData.body,
        width: '600px',
        height: 'calc(70vh - 40px)',
        confirm: {
            cancel: { label: 'Tamam' }
        },
    });
}
export default function Register() {
    var _a = useState(""), mobileInput = _a[0], setMobileInput = _a[1];
    var _b = React.useState(false), modalIsOpen = _b[0], setIsOpen = _b[1];
    var navigate = useNavigate();
    var validationSmsData;
    var refererReadOnly = false;
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function (member) {
            if (member.id !== undefined && member.status === 1) {
                navigate('/');
            }
        });
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    var searchParams = useSearchParams()[0];
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit, control = _c.control, getValues = _c.getValues, reset = _c.reset, errors = _c.formState.errors;
    if (searchParams.get('referer') && searchParams.get('referer') !== null) {
        var refer = searchParams.get('referer') || '';
        if (getValues('referer') === undefined) {
            reset({ referer: refer });
            refererReadOnly = true;
        }
    }
    var onSubmit = function (data) {
        callRegisterApi(data);
    };
    var callRegisterApi = function (data) {
        var registerBtn = document.getElementById('registerBtn');
        if (registerBtn) {
            registerBtn.disabled = true;
        }
        data.birthday = data.birthday_day + "/" + data.birthday_month + "/" + data.birthday_year;
        var cookieData = GetCookies();
        data.cookie = cookieData['initialTrafficSource'] || '';
        if (searchParams.get('utm_source')) {
            var cookieString = "utmcsr=".concat(searchParams.get('utm_source'), "|utmcmd=").concat(searchParams.get('utm_medium'), "|utmccn=").concat(searchParams.get('utm_campaign'));
            data.cookie = cookieString;
        }
        RegisterApi.register(data).then(function (result) {
            try {
                // @ts-ignore
                window.gtag('event', 'conversion', { 'send_to': 'AW-646617036/soXNCPS1v6sYEMyvqrQC' });
            }
            catch (e) {
            }
            try {
                // @ts-ignore
                fbq('trackCustom', 'UyeOl');
            }
            catch (e) { }
            if (result.status) {
                sendSMSValidationCode(data);
                if (registerBtn) {
                    registerBtn.disabled = false;
                }
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    timeout: 0,
                    confirm: {
                        cancel: {
                            label: 'Kapat'
                        }
                    }
                });
                if (registerBtn) {
                    registerBtn.disabled = false;
                }
            }
        });
    };
    var togglePassword = function (id) {
        var element = document.getElementById(id);
        var elementInput = document.getElementById(id + "Input");
        if (element.src.includes("eye-off")) {
            element.src = EyeIcon;
            elementInput.type = "text";
        }
        else {
            element.src = EyeOffIcon;
            elementInput.type = "password";
        }
    };
    var sendSMSValidationCode = function (data) {
        validationSmsData = {
            mobile: data.mobile,
            type: "register_send",
            token: null,
        };
        RegisterApi.validation_sms(validationSmsData).then(function (result) {
            if (result.status) {
                Object.assign(loginMember, data);
                setIsOpen(true);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    timeout: 0,
                    confirm: {
                        cancel: {
                            label: 'Kapat'
                        }
                    }
                });
            }
        });
    };
    function handleChange(e) {
        if (e[0] == "0" || e[0] == 0) {
            setMobileInput("");
        }
        else {
            setMobileInput(e.slice(0, 10));
        }
    }
    // @ts-ignore
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "container-fluid page login " }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "left-column" }, { children: _jsxs("ul", __assign({ className: "account-menu" }, { children: [_jsx("li", { children: _jsx("span", { children: "Bilgi Al" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: "Yard\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-verilerin-korunmasi" }, { children: "Ki\u015Fisel Verilerin Korunmas\u0131" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-veri-basvurulari" }, { children: "Ki\u015Fisel Veri Ba\u015Fvurular\u0131" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "register page right-column" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-lg-12" }, { children: _jsxs("div", __assign({ className: "card mt-sm-0 " }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Kay\u0131t Ol" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", __assign({ className: "table row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("label", { children: "T.C. Kimlik Numaran\u0131z" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('tc_id', { required: true, maxLength: 11, minLength: 11 })), void 0), errors.tc_id && errors.tc_id.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No gereklidir." }), void 0), errors.tc_id && errors.tc_id.type === "maxLength" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No 11 haneli olmal\u0131!" }), void 0), errors.tc_id && errors.tc_id.type === "minLength" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No 11 haneli olmal\u0131!" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("label", { children: "Ad\u0131n\u0131z" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('first_name', { required: true })), void 0), errors.first_name && errors.first_name.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Ad\u0131n\u0131z gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("label", { children: "Soyad\u0131n\u0131z" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('last_name', { required: true })), void 0), errors.last_name && errors.last_name.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Soyad\u0131n\u0131z gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Do\u011Fum Tarihi" }, void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "G\u00FCn" }, void 0), _jsxs("select", __assign({}, register('birthday_day', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), days.map(function (x) {
                                                                                                        return (_jsx("option", __assign({ value: x }, { children: x }), x));
                                                                                                    })] }), void 0), errors.birthday_day && errors.birthday_day.type === "required" &&
                                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "Ay" }, void 0), _jsxs("select", __assign({}, register('birthday_month', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), months.map(function (x) { return _jsx("option", __assign({ value: x.val }, { children: x.name }), x.val); })] }), void 0), errors.birthday_month && errors.birthday_month.type === "required" &&
                                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "Y\u0131l" }, void 0), _jsxs("select", __assign({}, register('birthday_year', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), years.map(function (x) { return _jsx("option", __assign({ value: x }, { children: x }), x); })] }), void 0), errors.birthday_year && errors.birthday_year.type === "required" &&
                                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "E-Posta" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('email', { required: true }), { type: "email" }), void 0), errors.email && errors.email.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "E-Posta gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Cep Numaran\u0131z" }, void 0), _jsx("div", __assign({ style: { position: 'absolute', fontSize: 16, marginTop: '7px', marginLeft: '10px' } }, { children: _jsx("span", __assign({ id: "basic-addon1" }, { children: "+90" }), void 0) }), void 0), _jsx("input", __assign({ maxLength: 10, type: "number", className: "form-control", style: { paddingLeft: '35px' } }, register('mobile', { required: true, maxLength: 10, minLength: 10 }), { onChange: function (e) { return handleChange(e.target.value); }, value: mobileInput }), void 0), errors.mobile && errors.mobile.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 gereklidir." }), void 0), errors.mobile && errors.mobile.type === "maxLength" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 10 haneli olmal\u0131!" }), void 0), errors.mobile && errors.mobile.type === "minLength" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 10 haneli olmal\u0131!" }), void 0), _jsx("p", { children: "Cep Telefonu numaran\u0131za g\u00FCvenli\u011Finiz gere\u011Fi do\u011Frulama kodu g\u00F6nderilecektir." }, void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u015Eifre" }, void 0), _jsx(Controller, { name: "password", control: control, rules: {
                                                                                    required: "Şifre belirlemelisiniz!"
                                                                                }, render: function () { return _jsx("input", __assign({}, register('password', { required: true }), { id: "defaultPasswordInput", name: "password", type: "password", placeholder: "", className: "form-control" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2", onClick: function () { return togglePassword("defaultPassword"); } }, { children: _jsx("img", { id: "defaultPassword", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.password && errors.password.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre gereklidir." }), void 0), errors.password && _jsx("p", { children: errors.password.message }, void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u015Eifre Tekrar" }, void 0), _jsx(Controller, { name: "password_repeat", control: control, rules: {
                                                                                    required: "Şifre belirlemelisiniz!",
                                                                                    //@ts-ignore
                                                                                    validate: function (value) {
                                                                                        if (value === getValues()["password"]) {
                                                                                            return true;
                                                                                        }
                                                                                        else {
                                                                                            return _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131, \u015Fifre ile e\u015Fle\u015Fmelidir." }), void 0);
                                                                                        }
                                                                                    }
                                                                                }, render: function () { return _jsx("input", __assign({}, register('password_repeat', { required: true }), { name: "password_repeat", type: "password", className: "form-control", id: "newPasswordInput" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2", onClick: function () { return togglePassword("newPassword"); } }, { children: _jsx("img", { id: "newPassword", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.password_repeat && errors.password_repeat.type === "required" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131 gereklidir." }), void 0), errors.password_repeat && _jsxs("p", { children: [" ", errors.password_repeat.message] }, void 0)] }), void 0), refererReadOnly && _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsxs("label", { children: ["Referans Kodu ", refererReadOnly === false ? _jsx("span", __assign({ className: "text-validation" }, { children: "* Zorunlu De\u011Fildir" }), void 0) : _jsx(_Fragment, {}, void 0)] }, void 0), _jsx("input", __assign({ className: "form-control", readOnly: refererReadOnly }, register('referer', { minLength: 6 })), void 0), errors.referer && errors.referer.type === "minLength" &&
                                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Ge\u00E7erli bir referans kodu giriniz." }), void 0)] }), void 0), _jsxs("div", { children: [_jsxs("label", __assign({ className: "mt-2" }, { children: [_jsx("input", __assign({}, register('usage_agreement', { required: true }), { type: "checkbox" }), void 0), _jsx("a", __assign({ href: "#", onClick: function () { return openStaticDataModal(6); } }, { children: "\u00DCyelik S\u00F6zle\u015Fmesi" }), void 0), "'ni okudum ve kabul ediyorum.", _jsx("small", { children: "*" }, void 0), errors.usage_agreement && errors.usage_agreement.type === "required" &&
                                                                                        _jsx("div", __assign({ className: "ml-4 text-validation" }, { children: "\u00DCyelik S\u00F6zle\u015Fmesini kabul etmelisiniz." }), void 0)] }), void 0), _jsxs("label", __assign({ htmlFor: "kvkk_agreement", className: "mt-2" }, { children: [_jsx("input", __assign({}, register('kvkk_agreement', { required: true }), { type: "checkbox" }), void 0), "Ki\u015Fisel Verilerin i\u015Flenmesine ili\u015Fkin \u00A0", _jsx("a", __assign({ href: "#", onClick: function () { return openStaticDataModal(3); } }, { children: "KVKK Ayd\u0131nlatma Metni" }), void 0), "'ni okudum ve kabul ediyorum.", _jsx("small", { children: "*" }, void 0), errors.kvkk_agreement && errors.kvkk_agreement.type === "required" &&
                                                                                        _jsx("div", __assign({ className: "ml-4 text-validation" }, { children: "KVKK Ayd\u0131nlatma Metnini okuyup kabul etmelisiniz." }), void 0)] }), void 0), _jsxs("label", __assign({ htmlFor: "campaign_agreement", className: "mt-2" }, { children: [_jsx("input", __assign({}, register('campaign_agreement'), { type: "checkbox" }), void 0), "Hediyeler, kampanyalar ve bana \u00F6zel f\u0131rsatlardan haberdar olmak istiyorum."] }), void 0)] }, void 0), _jsx("button", __assign({ disabled: false, className: "btn btn-primary mt-2", id: 'registerBtn' }, { children: "\u00DCye Ol" }), void 0)] }), void 0), _jsxs("label", __assign({ className: "mt-2" }, { children: ["Zaten bir hesab\u0131n\u0131z var m\u0131? ", _jsx(Link, __assign({ to: "/giris-yap" }, { children: "Giri\u015F Yap" }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0), _jsx(ValidationSmsModal, { modalIsOpen: modalIsOpen, setIsOpen: setIsOpen }, void 0)] }, void 0));
}
