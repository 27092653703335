var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { staticPagesList } from '../../store/static.pages.store';
import AboutUs from "./about.us";
import Contact from "./contact";
import { Storage } from "../../lib/localstorege.service";
import ResponsiblePlay from "./responsible.play";
var StaticPages = function (props) {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    console.log(staticPagesList.find(function (x) { return x.type == props.type; }));
    var staticPagesData = staticPagesList.find(function (x) { return x.type == props.type; })
        || Storage.get('staticPagesList').find(function (x) { return x.type == props.type; });
    console.log("staticPagesData", staticPagesList);
    return (_jsx(_Fragment, { children: props.type == "hakkimizda" ? (_jsx(_Fragment, { children: _jsx(AboutUs, {}, void 0) }, void 0)) : props.type == "iletisim" ? (_jsx(_Fragment, { children: _jsx(Contact, {}, void 0) }, void 0)) : props.type == 0 ? (_jsx(_Fragment, { children: _jsx(ResponsiblePlay, {}, void 0) }, void 0)) : (_jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", { className: "invert-color", dangerouslySetInnerHTML: { __html: staticPagesData.body } }, void 0) }), void 0) }), void 0)) }, void 0));
};
export default StaticPages;
