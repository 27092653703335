var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { loginSubject, useAuthContext } from "../../store/login.store";
import { useForm } from "react-hook-form";
import { callLoginApi } from "../../services/login.services";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../app/paths";
import '../custom.css';
import { betSlipVisiblity } from "../../shared/betslip/betslip";
import { location } from "../../store/login.store";
import ForgetPassword from "../../shared/widgets/forget.password";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
var LoginPage = function () {
    var navigate = useNavigate();
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var onSubmit = function (data) {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = true;
        }
        callLoginApi(data.username, data.password);
    };
    useEffect(function () {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function (member) {
            if (member.id !== undefined && member.status === 1) {
                setIsAuthenticated(true);
                var pathname = window.location.pathname.slice(1);
                if (pathname === paths.LOGIN || pathname === '') {
                    pathname = '/';
                }
                if (location.path) {
                    if (location.path == "back") {
                        window.history.back();
                    }
                    else if (location.path == "new-camp") {
                        navigate('/hediye-yagmuru?utm_source=oley&utm_medium=kampanyalar&utm_campaign=katildi');
                    }
                    else if (location.path == "yuksek-oran-camp") {
                        navigate('/tarafini-sec?utm_source=oley&utm_medium=kampanyalar&utm_campaign=yuksek-oran-katildi');
                    }
                    else if (location.path == "kaybetmek-yok-camp") {
                        navigate('/tarafini-sec?utm_source=oley&utm_medium=kampanyalar&utm_campaign=kaybetmek-yok-katildi');
                    }
                    else {
                        navigate(location.path);
                    }
                }
                else {
                    navigate('/');
                }
            }
        });
        if (isAuthenticated) {
            var pathname = window.location.pathname.slice(1);
            if (pathname === paths.LOGIN || pathname === '') {
                pathname = '/';
            }
            if (location.path) {
                if (location.path == "back") {
                    window.history.back();
                }
                else {
                    navigate(location.path);
                }
            }
            else {
                navigate('/');
            }
        }
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    var isPasswordVisible = function () {
        var password = document.getElementById("password");
        var eyeIcon = document.getElementById("eye-icon");
        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        }
        else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };
    return (_jsx("div", __assign({ className: "container-fluid page login " }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "left-column" }, { children: _jsxs("ul", __assign({ className: "account-menu" }, { children: [_jsx("li", { children: _jsx("span", { children: "Bilgi Al" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: "Yard\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-verilerin-korunmasi" }, { children: "Ki\u015Fisel Verilerin Korunmas\u0131" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-veri-basvurulari" }, { children: "Ki\u015Fisel Veri Ba\u015Fvurular\u0131" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "right-column" }, { children: _jsxs("div", __assign({ className: betSlipVisiblity ? "width-bet-slip card" : "w-100 card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u00DCye Giri\u015Fi" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "row table" }, { children: [_jsxs("div", __assign({ className: "col-lg-5 col-sm-12" }, { children: [_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u00DCye No / TC No / E-posta" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('username', { required: true })), void 0), errors.username &&
                                                                    _jsx("div", __assign({ className: "text-validation" }, { children: "\u00DCye No / TC No / E-Posta gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u015Eifre" }, void 0), _jsx("input", __assign({ type: "password", className: "form-control", id: "password" }, register('password', { required: true })), void 0), errors.password &&
                                                                    _jsx("div", __assign({ className: "text-validation" }, { children: "\u015Eifre gereklidir." }), void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2 position-absolute", onClick: function () { return isPasswordVisible(); } }, { children: _jsx("img", { id: "eye-icon", className: "eye-icon", height: 16, width: 16, src: EyeOffIcon }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "mt-2" }, { children: _jsx("input", { type: "submit", id: "id_btnLogin", className: "btn btn-primary mt-2", value: "Giri\u015F Yap" }, void 0) }), void 0)] }), void 0), _jsx(ForgetPassword, {}, void 0)] }), void 0), _jsx("div", __assign({ className: "col-lg-7 col-sm-12 mt-sm-4" }, { children: _jsxs("div", { children: [_jsxs("div", { children: [_jsx("p", { children: "\u00DCye olurken kulland\u0131\u011F\u0131n\u0131z e-posta adresiniz, \u00FCye numaran\u0131z ya da T.C. Kimlik Numaran\u0131z ve \u015Fifreniz ile birlikte Oley.com\u2019a giri\u015F yapabilirsiniz." }, void 0), _jsx("p", { children: "Oley.com\u2019da \u0130ddaa ve Spor Toto oynamak i\u00E7in \u00FCyeli\u011Finizin bulunmas\u0131 gerekmektedir." }, void 0), _jsx("p", { children: "Spor Toto'nun yasal bayisi olan Oley.com' a 18 ya\u015F\u0131ndan b\u00FCy\u00FCkler \u00FCye olabilir." }, void 0)] }, void 0), "\u00DCyelik ile ilgili detayl\u0131 bilgi i\u00E7in\u00A0", _jsx("span", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: " Yard\u0131m " }), void 0) }, void 0), "' a T\u0131kla", _jsx("div", __assign({ className: "my-2" }, { children: _jsx(Link, __assign({ className: "btn btn-primary btnRegister", to: "/kayit" }, { children: "Hemen \u00DCye Ol" }), void 0) }), void 0)] }, void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default LoginPage;
