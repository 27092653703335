var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Subject } from "rxjs";
export var modalServiceSubject = new Subject();
export function showInternetProblem(e) {
    if (e === void 0) { e = null; }
    modalServiceSubject.next({
        title: 'Beklenmedik bir hata oluştur',
        content: e ? String(e) : 'Lütfen internet bağlantınız kontrol edip tekrar deneyin',
        onClose: function () {
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
    });
}
var ModalService = function (props) {
    var _a = useState(false), showModal = _a[0], setShowModal = _a[1];
    useEffect(function () {
        var modalServiceSubject_subscription = modalServiceSubject.subscribe(function (obj) {
            if (obj) {
                setShowModal(obj);
                if (obj.timeout && obj.timeout > 0) {
                    setTimeout(function () {
                        setShowModal(null);
                    }, obj.timeout);
                }
            }
            else {
                unSetModal();
            }
        });
        return function () {
            modalServiceSubject_subscription.unsubscribe();
        };
    }, []);
    var unSetModal = function () {
        if (showModal && showModal.onClose) {
            showModal.onClose();
        }
        setShowModal(false);
    };
    if (showModal) {
        return (_jsxs(_Fragment, { children: [props.children, _jsxs("div", __assign({ className: "modal show", style: {
                        width: (showModal.width ? showModal.width : 'auto'),
                        maxWidth: (showModal.width ? 'calc( 100vw - 40px)' : '')
                    } }, { children: [showModal.title ? _jsx("div", __assign({ className: "header" }, { children: showModal.title }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { className: "body", style: { maxHeight: 'calc(100vh - 180px)', height: (showModal.height ? showModal.height : 'auto') }, dangerouslySetInnerHTML: { __html: showModal.content } }, void 0), _jsx("div", __assign({ className: "footer p-2 text-right" }, { children: showModal.confirm ? _jsxs(_Fragment, { children: [showModal.confirm.sure ? _jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn " + (showModal.confirm.sure.class || 'btn-primary'), onClick: function () {
                                                showModal.confirm.sure.action();
                                                unSetModal();
                                            } }, { children: showModal.confirm.sure.label }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), showModal.confirm.cancel ? _jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn " + (showModal.confirm.cancel.class || 'btn-default'), onClick: function () {
                                                if (showModal.confirm.cancel && showModal.confirm.cancel.action) {
                                                    showModal.confirm.cancel.action();
                                                    unSetModal();
                                                }
                                                else {
                                                    unSetModal();
                                                }
                                            } }, { children: (showModal.confirm.cancel && showModal.confirm.cancel.label) ? showModal.confirm.cancel.label : 'Vazgeç' }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0)] }), void 0), _jsx("div", { onClick: function () {
                        unSetModal();
                    }, className: "modal-backdrop show" }, void 0)] }, void 0));
    }
    return (_jsx(_Fragment, { children: props.children }, void 0));
};
export default ModalService;
